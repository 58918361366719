
header#top-bar-container {
    border-bottom: solid 1px rgba(123, 141, 177, .08);
    height: 65px;
    position: fixed;
    z-index: 26;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
}


.small-diamond-icon {
    background-image: url("/img/diamond-blue.svg");
    width: 24px;
    height: 24px;
}

.user-icon {
    background-image: url("/img/user-green.svg");
    width: 18px;
    height: 18px;
}

.profile-menu-dialog-content {
    width: 180px;
    position: absolute !important;
    top: 60px;
    right: 30px;
    max-width: 100vw !important;
}

.profile-menu-dialog-content .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 0 !important;
}

i.mobile-menu-button {
    background-image: url("/img/mobile-menu-icon.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: none;
}

.hire-experts-button-container button {
    color: #253658;
    font-family: Inter-SemiBold;
}

.hire-experts-button-container:hover {
    opacity: .5;
}

.hire-experts-dialog-content {
    width: 680px;
}

.hire-experts-dialog-content .mat-mdc-dialog-surface {
    padding: 30px 25px 30px 30px !important;
    border-radius: 10px !important;
}

@media only screen and (max-width: 1023px) {
    .hire-experts-dialog-content {
        width: 95%;
        max-width: 770px !important;
    }
}



@media only screen and (max-width: 767px) {

    .user-icon {
        width: 15px;
        height: 15px;
    }

    .small-diamond-icon {
        width: 15px;
        height: 15px;
    }

    i.mobile-menu-button {
        display: inline-block;
    }

    .profile-menu-dialog-content {
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        max-width: 100vw;
        margin: 0 auto;
    }

    .profile-menu-dialog-content .mat-mdc-dialog-surface {
        border-radius: 0 !important;
    }

    .hire-experts-dialog-content {
        height: 90%;
        max-width: 400px !important;
    }

    .hire-experts-dialog-content .mat-mdc-dialog-surface {
        padding: 15px !important;
    }

}


/*CONFIRMATION DIALOG*/
.confirmation-dialog-container {
    position: absolute !important;
    top: 170px;
}


.confirmation-dialog-container .mat-mdc-dialog-surface {
    border-radius: 10px !important;
}

.confirmation-msg {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    max-width: 450px;
}

confirmation-dialog .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    align-content: center;
}

confirmation-dialog .button-container button {
    margin: 0 5px;
}

.confirmation-btn {
    display: flex;
    justify-content: center;
}

.confirmation-btn button {
    width: 126px;
    height: 32px;
    background-color: #12b1fe;
    border-radius: 22.5px;
    color: #fff;
}

.confirmation-btn button:hover {
    background-color: #fff;
    color: #12b1fe;
    border: 1px solid #12b1fe;
}

.confirmation-header {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}

@media only screen and (max-width: 1023px) {
    .confirmation-dialog-close {
        right: -23px !important;
    }

    .confirmation-msg {
        font-size: 14px;
        margin-bottom: 20px;
    }
}

/*PREMIUM GUARD DIALOG*/

.buy-premium-button:hover {
    opacity: 0.8;
}

.ngdialog.ngdialog-theme-buy-premiume .ngdialog-content {
    -webkit-animation: ngdialog-flyin .5s;
    animation: ngdialog-flyin .5s;
    background: #f0f0f0;
    color: #444;
    font-family: 'Helvetica',sans-serif;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 0px auto;
    max-width: 100%;
    padding: 1em;
    position: absolute;
    left: calc(50% - 126px);
    top: calc(50% - 76px);
    width: 252px;
    height: 152px;
    object-fit: contain;
    border-radius: 10px;
}

.media-library-component-container {
    margin-top: 22px;
}

.media-library-component-container .header-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.media-library-component-container .header-container .header-menu {
    width: 23%;
    font-size: 26px;
    font-weight: 600;
    margin-top: -10px;
}

.media-library-component-container .header-container .header-toolbar-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.media-library-header-menu {
    width: 224px;
    position: absolute;
    top: -6px;
}

.chevron-header-menu:before {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: '';
    display: inline-block;
    height: 10px;
    position: relative;
    vertical-align: top;
    width: 10px;
    cursor: pointer;
    top: 10px;
    right: 5px;
    transform: rotate(135deg);

}

.asset-type-menu-container {
    width: 112px;
    margin: 0 22px;
    background-color: #00abfe;
    color: #fff;
    border-radius: 5px;
}

.chevron-asset-types::before {
    left: -2px !important;
}

.media-library-component-container .upload-button-container {
    color: #fff;
    background-color: #00abfe;
    letter-spacing: 0.77px;
    text-align: center;
    border-radius: 25.5px;
    width: 104px;
    height: 34px;
    padding: 7px 5px;
    margin: 0 40px;
}

.media-library-component-container .upload-button-container:hover {
    opacity: .5;
}

.media-library-component-container .upload-button-container button {
    width: 100%;
}

.media-library-component-container .assets-list-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 30px auto;
    flex-wrap: wrap;
}

.media-library-component-container .assets-list-container media-box.media-box-item {
    width: 22%;
    margin: 13px;
}

.audio-preview-dialog-content {
    width: 450px;
    height: 134px;
    position: absolute !important;
    top: 200px;
}

.audio-preview-dialog-content .mat-mdc-dialog-surface {
    padding: 8px;
}

.image-preview-dialog-content {
    width: 450px;
    height: 300px;
}

.image-preview-dialog-content .mat-mdc-dialog-surface {
    padding: 0px;
}

.upload-button-container input {
    display: none;
}

.assets-list-container upload-media-box {
    width: 22%;
    margin: 13px;
}

@media only screen and (max-width: 1023px) {
    .media-library-component-container .header-container .header-toolbar-container {
        position: relative;
        width: 100%;
    }

    .media-library-component-container .header-container {
        flex-wrap: wrap;
    }

    .media-library-component-container .header-container .header-menu {
        width: 37%;
        margin-bottom: 10px;
    }

    .header-toolbar-container workspace-menu-bar {
        margin: 0 10px;
    }

    .media-library-component-container .upload-button-container {
        position: absolute;
        top: -53px;
        right: 47px;
    }

    .media-library-component-container .assets-list-container media-box.media-box-item {
        width: 30%;
        margin: 10px;
    }

    .assets-list-container upload-media-box {
        width: 30%;
        margin: 10px;
    }

    .asset-type-menu-container {
        margin: 0 0 0 10px;
    }
}

@media only screen and (max-width: 767px) {
    .media-library-component-container .header-container {
        flex-direction: column;
        margin: 0 auto;
        max-width: 300px;
    }

    .media-library-component-container .header-container .header-menu {
        width: 70%;
        font-size: 21px;
        margin: 0px -13px 10px;
    }

    .media-library-component-container .header-container .header-toolbar-container {
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 20px;
        width: 100%;
    }

    .header-toolbar-container workspace-menu-bar {
        width: 100%;
        margin: unset;
    }

    .asset-type-menu-container {
        width: 43%;
        margin: 20px 0 0 10px;
    }

    .chevron-header-menu:before {
        right: -10px;
    }

    .media-library-component-container .upload-button-container {
        position: absolute;
        top: -49px;
        right: 0px;
        width: 80px;
        font-size: 13px;
        margin: 0;
    }

    .media-library-header-menu {
        width: 180px;
        left: 13px;
    }

    .media-library-asset-type-menu {
        width: 125px !important;
    }

    .media-library-component-container .assets-list-container media-box.media-box-item {
        width: 100%;
        margin: 0px auto 10px;
        max-width: 300px;
    }

    .ngdialog.ngdialog-theme-default.ng-dialog-preview-video .ngdialog-content {
        width: unset;
        margin: 0px 5px;
    }

    .image-preview-dialog-content {
        max-width: none !important;
        margin: 0px 5px;
    }

    .audio-preview-dialog-content {
        width: 94%;
        max-width: 400px !important;
    }

    .assets-list-container upload-media-box {
        width: 100%;
        margin: 0px auto 10px;
        max-width: 300px;
    }
}

