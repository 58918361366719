
#editorcontainer{
    width: 40%;
    height: 40%;
}

.ace_editor.ace-jsoneditor {
    min-height: 500px;
}
/*:host {*/
/*  position: absolute;*/
/*  left: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  top: 0;*/
/*  width: 1440px;*/
/*}*/

.themeEditor_container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    height: calc(100% - 70px);
    align-items: stretch;
    background-color: #ecf5fc;
}
.themeEditor-previewer-container {
    background-color: #EBEEF8;
    height: calc(100vh - 67px);
}
.themeEditor-editor-container{
    background-color: #F4FAFE;
    height: calc(100vh - 67px);
    overflow-y: scroll;
    min-height: 150px;
    border: 1px solid #ddd;
    padding: 15px;
    position: relative;
    float: right;
    width: 30vw;
    padding-top: 0;
    padding-right: 0;
    z-index: 13;
    overflow: hidden;
}

/*@import 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap';*/

/*html, body {*/
/*  margin: 0;*/
/*  height:100vh;*/
/*  overflow: hidden;*/
/*}*/
/** {*/
/*  box-sizing: border-box;*/
/*}*/

/*!*TYPOGRAPHY*!*/


.theme-editor-h1, .theme-editor-h2, .theme-editor-h3, .theme-editor-h4, .theme-editor-h5{
    /*font-family: Inter, fantasy;*/
    color: var(--BlueBlack);
}
.theme-editor-dark{
    color: var(--BlueBlack);
}
.theme-editor-light{
    color: var(--colors-black-blue-70);
}
.theme-editor-bold{
    font-weight: 600;
}
.theme-editor-h1{
    font-size: 26px;
}
.theme-editor-h2{
    font-size: 18px;
}
.theme-editor-h3{
    font-size: 14px;
}
.theme-editor-h4{
    font-size: 12px;
}
.theme-editor-h5{
    font-size: 10px;
}
/*BUTTONS*/
/*should be changed*/
/*button{*/
/*  font-size: 100%;*/
/*  font-family: inherit;*/
/*  border:0;*/
/*  padding:0*/
/*}*/
/*button:disabled{*/
/*  opacity: 0.6;*/
/*}*/
.theme-editor-action-container{
    border-radius: 10px;
    background-color: var(--DeadBlue-8);
    text-align: center;
}
/*ICONS*/
.theme-editor-icon40 {
    width: 40px;
    height: 40px;
    object-fit: contain;
}
.theme-editor-icon34 {
    width: 34px;
    height: 34px;
    object-fit: contain;
}
.theme-editor-icon24 {
    width: 24px;
    height: 24px;
    object-fit: contain;
}
/*SETTING*/
.theme-editor-controllers-side-padding{
    padding: 0 24px 0 24px;
    width: 100%;
}

/*UNCATAGORIZED (yet)*/
.theme-editor-box-shadow{
    box-shadow: 0 0 10px -2px rgba(0,0,0,0.75);
}

.theme-editor-selected{
    box-shadow: 0 0 0 2px #8acb2a;
}
.theme-editor-hovered{
    box-shadow: 0 0 0 2px var(--Primary);

}
.theme-editor-marked{
    box-shadow: 0 0 0 2px greenyellow;
}
/*should be reduntent*/
.theme-editor-component {
    background-color: white; border-radius: 10px; padding:10px; margin:10px 0 10px 0;
    width: 90%;
    align-self: center
}

/*JSON-EDITOR*/
.ace_editor.ace-jsoneditor {
    min-height: 500px;
}
/*FLEXBOX*/
.theme-editor-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.theme-editor-column-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.theme-editor-center-one-div-inside{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/*COLORS*/
/* .theme-editor-main-container {
    --Primary: #00abfe;
    --Greenz: #00d096;
    --Black: #000000;
    --White: #ffffff;
    --Black-60: rgba(0, 0, 0, 0.6);
    --Black-30: rgba(0, 0, 0, 0.3);
    --Black-8: rgba(0, 0, 0, 0.08);
    --Pinky: #ff86bc;
    --Redish: #fd616d;
    --Redish-60: rgba(253, 97, 109, 0.6);
    --Redish-30: rgba(253, 97, 109, 0.3);
    --Redish-8: rgba(253, 97, 109, 0.08);
    --White-60: rgba(255, 255, 255, 0.6);
    --White-30: rgba(255, 255, 255, 0.3);
    --White-8: rgba(255, 255, 255, 0.08);
    --Primary-60: rgba(0, 171, 254, 0.6);
    --Primary-30: rgba(0, 171, 254, 0.3);
    --Primary-8: rgba(0, 171, 254, 0.08);
    --DeadBlue: #7b8db1;
    --DeadBlueOpacity08: #f5f6fa;
    --DeadBlue-60: rgba(123, 141, 177, 0.6);
    --DeadBlue-8: rgba(123, 141, 177, 0.08);
    --BlueBlack: #253658;
    --Royal: #4481fd;
    --colors-dead-blue-30: rgba(123, 141, 177, 0.3);
    --colors-black-blue-8: rgba(37, 54, 88, 0.08);
    --colors-black-blue-30: rgba(37, 54, 88, 0.3);
    --colors-black-blue-70: rgba(37, 54, 88, 0.6);
    --colors-greenz-60: rgba(0, 208, 150, 0.6);
    --colors-purple: #aa58ee;
    --blue-gradient: linear-gradient(to left, #28b3f7 1%, #506aff);
    --grey-gradient: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), linear-gradient(to left, #28b3f7 99%, #506aff 1%);
} */

/*:root{*/
/*  --nav-bar-height: 60px;*/
/*}*/

/*.testBox {*/
/*  width: 200px;*/
/*  height: 200px;*/
/*  background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%);*/
/*}*/
:host{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:30;
}

.theme-editor_sidebar-container {
    background-color: #ebeff8
}

.theme-editor_editor_container {
    /*background-color: white;*/
    /*box-shadow: 18px 0 50px 0 rgba(0, 0, 0, 0.15);*/
    background-color: #ebeff8

}

.theme-editor_previewer-container {
    /*background-color:var(--DeadBlue);*/
}
.theme-editor-main-container{
    background-color: #ebeff8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
}
