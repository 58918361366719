@font-face {
  font-family: Heebo-Black;
  src: url('/fonts/Heebo-Black.woff2');
}

@font-face {
  font-family: Heebo-Bold;
  src: url('/fonts/Heebo-Bold.woff2');
}

@font-face {
  font-family: Heebo-ExtraBold;
  src: url('/fonts/Heebo-ExtraBold.woff2');
}

@font-face {
  font-family: Heebo-ExtraLight;
  src: url('/fonts/Heebo-ExtraLight.woff2');
}

@font-face {
  font-family: Heebo-Light;
  src: url('/fonts/Heebo-Light.woff2');
}

@font-face {
  font-family: Heebo-Medium;
  src: url('/fonts/Heebo-Medium.woff2');
}

@font-face {
  font-family: Heebo-Regular;
  src: url('/fonts/Heebo-Regular.woff2');
}

@font-face {
  font-family: Heebo-SemiBold;
  src: url('/fonts/Heebo-SemiBold.woff2');
}

@font-face {
  font-family: Heebo-Thin;
  src: url('/fonts/Heebo-Thin.woff2');
}

*:lang(he) {
  font-family: Inter-Regular, Heebo-Regular, sans-serif;
}

body:lang(he) {
  font-family: Inter-Regular, Heebo-Regular, Helvetica, Arial, sans-serif;
}

.extra-bold:lang(he) {
  font-family: Inter-ExtraBold, Heebo-ExtraBold, sans-serif !important;
}

.bold:lang(he) {
  font-family: Inter-Bold, Heebo-Bold, sans-serif !important;
}

.semi-bold:lang(he) {
  font-family: Inter-SemiBold, Heebo-SemiBold, sans-serif !important;
}




