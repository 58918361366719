.image-edit-dialog-container mat-dialog-container.mat-mdc-dialog-container {
    border-radius: 8px;
    width: 400px;
    height: 400px;
}

image-edit-dialog > .asset-dialog-header {
    font-size: 26px;
    margin: -15px -10px 0;
    font-weight: bold;
    color: #000;
}

image-edit-dialog > .header-separator {
    height: 1px;
    background-color: #e7e7e7;
    margin: 10px -24px 0;
    width: 580px;
}

.image-edit-container {
    width: 309px;
    margin: 0 auto 0;
}

.crop-control-container {
    width: 100%;
}

.crop-control-container > mat-slider.mat-mdc-slider {
    width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-track-background {
    background-color: #e1e1e1;
}

.image-cropper-dimensions image-crop image-cropper.image-cropper {
    padding: 0px !important;
}

@media only screen and (max-width: 1023px) {

    .image-edit-dialog-container {
        width: 540px;
        max-width: none !important;
        margin: 0 6px;
    }

    image-edit-dialog > .asset-dialog-header {
        font-size: 24px;
    }

    image-edit-dialog > .header-separator {
        width: auto;
    }

    .asset-dialog-close {
        right: 0px;
    }
}
.image-cropper-wrapper .cropper::after , .image-cropper-wrapper .move{
    border-radius: 10px;
}
@media only screen and (max-width: 767px) {
    .image-edit-dialog-container {
        width: 100%;
    }

    image-edit-dialog > .asset-dialog-header {
        font-size: 18px;
    }
    
    .asset-dialog-close {
        right: -24px;
    }
    
    .asset-dialog-close:before, .asset-dialog-close:after {
        height: 16px;
        left: 13px;
    }
    
    .image-edit-container {
        width: 100%;
    }
    
    image-edit-dialog > .header-separator {
        width: auto;
    }
    
    .change-email-dialog-btn-container > button {
        font-size: 12px;
    }
    
}
