.backdropBackground {
    -webkit-backdrop-filter: blur(6px) !important;
    backdrop-filter: blur(6px) !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.medium-editor-placeholder-relative:after,
.medium-editor-placeholder:after {
    font-style: normal;
}

.video-popup-details-dialog-medium-editor-wrapper .medium-editor-element {
    min-height: 100px
}

.hidden {
    display: none;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

@media screen and (max-width: 450px) {
    .medium-editor-element {
        max-height: 100px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.81;
        letter-spacing: -0.39px;
        color: #000000;
        padding-left: 3px;
        max-width: 300px !important;
        min-width: 300px !important;
    }
}

.medium-editor-element {
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: -0.39px;
    color: #000000;
    padding-left: 3px;
    word-break: break-word;
}

.medium-editor-element ul {
    list-style: disc !important;
}

.medium-editor-placeholder {
    position: relative;
    opacity: .3;
}

.medium-editor-element>h2 {
    margin-top: 0;
    margin-bottom: 0;
}

.medium-editor-element>h3 {
    margin-top: 0;
    margin-bottom: 0;
}

.medium-editor-element h2 {
    margin-top: 0;
    margin-bottom: 0;
}

.medium-editor-element h3 {
    margin-top: 0;
    margin-bottom: 0;
}

.medium-editor-element p {
    margin: 0;
}

.medium-editor-toolbar {
    border-radius: 6px !important;
}

.header1-button-toolbar {
    font-size: 23px;
    font-family: auto;
}

.header3-button-toolbar {
    font-size: 18px;
    font-family: auto;
}


.medium-editor-toolbar li button:hover {
    opacity: .6 !important;
}

.medium-editor-toolbar li .medium-editor-button-first {
    padding-left: 12px;
}

.medium-editor-toolbar li .medium-editor-button-last {
    padding-right: 12px;
}


.header-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 50%;
}


@media screen and (max-width: 1200px) {
    .discard-modal-container {
        max-width: 100% !important;
    }
}

.discard-modal {
    padding-bottom: 20px !important;
}

.asset-dialog-header {
    font-size: 26px;
    font-weight: bold;
    color: #000;
}


