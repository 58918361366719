.affiliate-title {
    margin-top: 100px;
    font-size: 40px;
    font-weight: 600;
}

.affiliate-text {
    font-size: 18px;
    color: #6d7278;
    padding: 26px 0;
}

.affiliate-bold {
    font-size: 24px;
    font-weight: 500;
}

.bigvu-btn-apply.affiliate-join-now-btn {
    width: 256px;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
    margin: 60px auto 0;
    display: none;
}

.affiliate-plan-container {
    text-align: center;
    flex: 1 0;
    height: 300px;
    position: relative;
    max-width: 330px;
}

.affiliate-pricing-title {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 70px;
}

.affiliate-start {
    margin:0px;
    padding: 15px 30px 30px;
}

.affiliate-start .affiliate-pricing-title {
    margin-bottom: 0px;
}

.affiliate-plans-wrapper {
    display: flex;
    margin-bottom: 30px;
}

.affiliate-plan-container:last-of-type {
    border-radius: 26px;
    background-color: rgba(179, 187, 191, 0.24);
}

.affiliate-plan-title {
    font-size: 34px;
    font-weight: 600;
    margin: 20px 0 10px;
}

.affiliate-plan-subtitle {
    font-size: 16px;
}

.affiliate-plan-price span.price {
    font-size: 44px;
    font-weight: bold;
}

.affiliate-plan-price {
    margin-top: 30px;
}

.affiliate-subscribe > button.button-subscribe {
    width: 180px;
    height: 36px;
    position: absolute;
    top: calc(100% - 36px - 35px);
    left: 0;
    right: 0;
    margin: 0 auto;
}

.affiliate-plan-price .symbol {
    width: 11px;
    height: 26px;
    font-size: 22px;
    position: relative;
    top: -18px;
    right: -4px;
}

.affiliate-terms .conditions:last-of-type {
    margin-bottom: 12px;
}

.affiliate-terms .conditions {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    color: #aaaaaa;
}

.row.visit-website {
    font-size: 16px;
    margin-top: 30px;
    text-align: center;
}

.affiliate-badges-wrapper {
    justify-content: space-evenly;
    display: flex;
}

img.store-badge {
    max-height: 46px;
}

.affiliate-videos-wrapper {
    border-radius: 26px;
    background-color: rgba(179, 187, 191, 0.24);
    font-size: 18px;
    padding: 25px;
}

.affiliate-videos-wrapper > div {
    margin-top: 20px;
}

.affiliate-plan-price.monthly .plan {
    word-wrap: break-word;
    font-size: 14px;
    color: #7b7b7b;
}

.affiliate-plan-price.monthly {
    padding: 20px;
}

.affiliate-youtube-wrapper > a {
    margin-left: 4px;
    color: black; 
    text-decoration: underline;
}

@media (max-width: 992px) {
    .bigvu-btn-apply.affiliate-join-now-btn {
        display: block;
    }

    .affiliate-title {
        margin-top: 10px;
    }

    .affiliate-cell {
        height: 100vh;
    }

    .affiliate-plan-title {
        font-size: 23px;
    }

    .affiliate-plan-container {
        margin: 0 15px;
    }

    .affiliate-plan-subtitle {
        font-size: 13px;
    }

    .affiliate-plan-price span.price {
        font-size: 18px;
    }

    .affiliate-plan-price .symbol {
        font-size: 18px;
        top: 0;
        right: -3px;
    }

    .affiliate-subscribe > button.button-subscribe {
        width: max-content;
    }

    .affiliate-plan-container {
        height: 251px;
    }

    img.store-badge {
        max-height: 40px;
        margin: 10px auto;
    }

    .affiliate-plan-price.monthly .plan {
        word-wrap: break-word;
        font-size: 10px;
    }

    .affiliate-plan-price.monthly {
        padding: 0;
        margin: 10px
    }
}
