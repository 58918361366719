.full-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

@media (min-width: 1200px) {
    .container {
        width: 1200px;
    }
}

.container {
    margin: 0 40px;
}

.container-login {
    margin: 0;
}

md-content {
    background-color: #ffffff !important;
}

input[type="checkbox"] {
    margin-top: 12px;
    cursor: pointer;
}

.grey-down-arrow {
    background-image: url("/img/grey-down-arrow.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 24px;
    height: 24px;
    display: inline-block;
}


.blue-big-arrow {
    background-image: url("/img/blue-big-toogle.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 20px;
    height: 20px;
    display: inline-block;
}

md-toolbar.bigvu-tabs md-tabs-wrapper {
    background-color: white !important;
}

md-toolbar.bigvu-tabs md-tab-item {
    list-style-type: none;
    margin-right: 32px;
    font-size: 14px !important;
    line-height: 1.43 !important;
    font-weight: 400 !important;
    color: #909090 !important;
    height: 36px;
    cursor: pointer !important;
    font-family: 'Open Sans' !important;
    padding: 0 10px !important;
    text-transform: capitalize;
}

md-toolbar.bigvu-tabs md-tab-item:hover {
    color: #11a9fe !important;
}

md-toolbar.bigvu-tabs md-tabs-canvas {
    height: 36px !important;
}

md-toolbar.bigvu-tabs {
    height: 36px !important;
    min-height: 36px !important;
    background-color: white !important;
}

md-toolbar.bigvu-tabs md-tab-item.md-active:after,
md-toolbar.bigvu-tabs md-tab-item:hover:after {
    content: '';
    position: absolute;
    border-bottom: 3px solid #11a9fe;
    bottom: 0;
    left: 0;
    right: 0;
}

md-toolbar.bigvu-tabs md-tab-item.md-active {
    font-weight: bold !important;
    color: #11a9fe !important;
    position: relative;
}

.dropdown ul.dropdown-menu li a {
    cursor: pointer;
}

.ui-notification {
    z-index: 11000;
}

i.fa.fa-external-link.fa-lg.embed-temp-icon {
    position: relative;
    top: 0px;
    left: 3px;
    margin-right: 7px;
    color: #11a9fe;
}

.disabled-section i[role=button] {
    cursor: not-allowed;
}

.pagination {
    border-radius: 0;
}

.pagination > li {
    margin: 0 2px;
    display: inline-block;
    vertical-align: top;
}

.pagination > li > a,
.pagination > li > span {
    border-radius: 50% !important;
    padding: 0;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    font-size: 14px;
    z-index: 1;
    position: relative;
}

.pagination > li > a > .md,
.pagination > li > span > .md {
    font-size: 22px;
}

.pagination > li.disabled {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.pagination > li > a,
.pagination > li > span {
    width: 24px;
    height: 24px;
    border-radius: 3px !important;
    background-color: #ffffff;
    border: solid 1px rgba(151, 151, 151, 0.2);
    line-height: 23px;
    font-family: HelveticaNeue, Helvetica;
    font-size: 14px;
    font-weight: 500;
    color: #909090;
    position: relative;
    float: left;
    text-decoration: none;
    padding: 0;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #5e84ff !important;
    color: white !important;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #777777;
    background-color: #e2e2e2;
    border-color: #ffffff;
    cursor: not-allowed;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 18px 0;
    border-radius: 2px;
}

.no-border {
    border: 0 !important;
}

div.vdm-slides * {
    -webkit-box-sizing: unset !important;
    -moz-box-sizing: unset !important;
    box-sizing: unset !important;
}

.purple-gradient {
    background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(319deg, #a851ff, #6492ff);
}

.gray-gradient {
    background-image: linear-gradient(319deg, #c2d5e0, #c3dae1);
}

.orange-gradient {
    background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(319deg, #ff797b, #ffaf74);
}

.capitalize {
    text-transform: capitalize;
}

.subscribe-btn {
    height: 35px;
    border-radius: 4px;
    background-color: #11a9fe;
    border: 0;
    opacity: 0.9;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    padding: 0 14px;
    line-height: 35px;
    display: inline-block;
    cursor: pointer;
}

.subscribe-btn > span {
    margin-left: 8px;
}

.subscribe-btn > img {
    margin-top: -2px;
}

.subscribe-btn:hover {
    background-color: rgba(17, 169, 254, 0.1);
    color: #11a9fe;
}

button.btn-apply {
    height: 30px;
    border-radius: 100px;
    background-color: #11a9fe;
    border: 0;
    opacity: 1;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    padding: 0 14px;
    line-height: 30px;
    cursor: pointer;
}

button.btn-apply:hover {
    background-color: rgba(17, 169, 254, 0.1);
    color: #11a9fe;
}

button.btn-dismiss {
    height: 30px;
    border-radius: 100px;
    background-color: white;
    border: 0;
    opacity: 1;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #000000;
    padding: 0 14px;
    line-height: 30px;
    cursor: pointer;
}

button.btn-dismiss:hover {
    background-color: #fff1f1;
    color: #f77676;
    border-color: #f77676;
}

button.btn-dismiss i.zmdi.zmdi-close {
    margin-right: 3px;
    position: relative;
    top: 1px;
}

.close-upload-icon {
    background-image: url("/img/exit-white-icon-contained.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 12px;
    height: 12px;
    display: block;
    cursor: pointer;
}



button.dropdown-toggle {
    border: 0;
    background: transparent;
    box-shadow: none;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    color: #606060;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
}

button.dropdown-toggle .caret {
    position: relative;
    top: -2px;
    margin-left: 6px;
}

button.btn-transperant {
    background-color: transparent;
    border: 0;
}

.dropdown:not(.colorpicker) .dropdown-menu,
.dropup .dropdown-menu,
.dropdown-menu.append-to-body {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: transform 0.3s, opacity 0.3s, filter 0.3s;
    transform-origin: top left;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    /*opacity: 0;*/
    filter: alpha(opacity=0);
    display: block;
    border: 0;
}

.dropup .dropdown-menu {
    transform-origin: bottom left;
}

.dropdown.open:not(.colorpicker) .dropdown-menu,
.dropup.open .dropdown-menu,
.uib-dropdown-open > .dropdown-menu.append-to-body {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.dropdown:not(.colorpicker) .dropdown-menu a,
.dropup .dropdown-menu a,
.dropdown-menu.append-to-body li {
    height: 31px;
    line-height: 26px !important;
    color: #4C4C4C;
}

.bigvu-btn-apply,
.bigvu-btn-action,
.bigvu-btn-dismiss {
    height: 28px;
    padding: 0 15px;
    border-radius: 100px;
    border: 0;
    color: white;
}

.bigvu-btn-apply.small,
.bigvu-btn-action.small,
.bigvu-btn-dismiss.small {
    height: 20px;
    padding: 0 8px;
}

.bigvu-btn-apply {
    background-color: rgba(17, 169, 254, 0.95);
}

.bigvu-btn-action {
    background-color: rgba(0, 218, 144, 0.95);
}

.bigvu-btn-dismiss {
    background-color: rgba(247, 118, 117, 0.95);
}

.bigvu-btn-apply span,
.bigvu-btn-action span,
.bigvu-btn-dismiss span {
    font-family: 'Open Sans', sans-serif;
    line-height: 27px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: white;
}

.bigvu-btn-action:hover {
    opacity: .5;
}

.bigvu-btn-apply:hover {
    background-color: rgba(17, 169, 254, 0.1);
    color: rgba(17, 169, 254, 0.95) !important;
}

.bigvu-btn-apply:hover span {
    color: rgba(17, 169, 254, 0.95) !important;
}

.bigvu-btn-dismiss:hover {
    background-color: #fef1f1;
    color: #F77675 !important;
}

.bigvu-btn-dismiss:hover span {
    color: #F77675;
}

button.bigvu-btn-apply[disabled],
button.bigvu-btn-apply[disabled]:hover {
    background-color: #e4e4e4;
    color: white !important;
}

button.btn-icon {
    border-radius: 100%;
    background-color: transparent;
    padding: 0;
    font-size: 18px;
}

.table > thead > tr > th {
    font-weight: 500;
    color: #333;
    border-width: 1px;
}

.no-padding {
    padding: 0;
}

.big-bigvu-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #c6c6c6;
    font-family: 'Open Sans';
    font-size: 14px;
    color: #606060;
    padding-left: 15px;
    -webkit-appearance: none;
    -webkit-border-radius: 4px;
}

.bigvu-input-label {
    font-size: 12px;
    height: 16px;
    color: #999999;
    margin-top: 17px;
}

span.caret.caret-double:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-bottom: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    margin-top: -11px;
    margin-left: -4px;
}

span.caret.caret-double {
    margin-top: 7px;
}

.dropdown-menu.dropdown-menu-right {
    transform-origin: top right;
}

.dropdown-menu.dropup-menu-right {
    transform-origin: bottom right;
}

md-tabs.md-default-theme md-ink-bar,
md-tabs md-ink-bar {
    color: rgb(35, 172, 254) !important;
    background: rgb(35, 172, 254) !important;
    height: 3px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    border-radius: 8px;
    color: #999999;
}

::-webkit-scrollbar:horizontal {
    height: 11px;
}

::-webkit-scrollbar:vertical {
    /*width: 11px;*/
    width: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #bcddf6;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.dropdown > a.action:after,
.actions > a:after {
    content: "";
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    vertical-align: middle;
    -webkit-transform: scale3d(0, 0, 0);
    -moz-transform: scale3d(0, 0, 0);
    -ms-transform: scale3d(0, 0, 0);
    -o-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
    z-index: 0;
    transition: all 0.25s linear 0s;
    opacity: 0;
    filter: alpha(opacity=0);
}

.dropdown > a.action:hover:after,
.actions > a:hover:after {
    -webkit-transform: scale3d(3, 3, 3);
    -moz-transform: scale3d(3, 3, 3);
    -ms-transform: scale3d(3, 3, 3);
    -o-transform: scale3d(3, 3, 3);
    transform: scale3d(3, 3, 3);
    opacity: 1;
    filter: alpha(opacity=100);
}

input.newAsset {
    display: none !important;
}

.modal-footer {
    border: 0;
}

.mark-drop-area {
    border: #979797;
    border-width: 2px;
    border-style: dashed;
}

.mark-drop-area-opacity {
    border: 2px dashed black;
}

.ngdialog-confirm {
    padding: 15px;
    display: inline-block;
}

.ngdialog-confirm span {
    font-size: 20px;
}

.ngdialog-confirm .ngdialog-buttons {
    margin-top: 50px;
}

.repeat-animation.ng-move,
.repeat-animation.ng-enter,
.repeat-animation.ng-leave {
    -webkit-transition: opacity linear 0.5s;
    transition: opacity linear 0.5s;
}

.repeat-animation.ng-leave.ng-leave-active,
.repeat-animation.ng-move,
.repeat-animation.ng-enter {
    opacity: 0;
}

.repeat-animation.ng-leave,
.repeat-animation.ng-move.ng-move-active,
.repeat-animation.ng-enter.ng-enter-active {
    opacity: 1;
}

button.bigvu-btn-link {
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    font-size: 14px;
    line-height: 30px;
    font-weight: 800;
}

.blue {
    color: #23adfe;
}

.blue:hover {
    color: #8ca6f8;
}

a[ng-click] {
    cursor: pointer;
}


.info-snackbar {
    background: #23adfe;
}

.success-snackbar {
    background: #1bdd96;
}

.error-snackbar {
    background: #ff5555;
}

.warning-snackbar {
    background: #fcab51;
}

.custom-snackbar button {
    display: none !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: unset !important;
    box-shadow: unset !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    padding: 0 !important;
}

.custom-snackbar {
    border-radius: 3px !important;
    padding: 12px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3) !important;
    z-index: 214748300121 !important;
    line-height: 22px !important;
    font-size: 14px !important;
    transition: all 2s ease-in-out;
}


div#mixpanel-notification-tagline {
    display: none !important;
}

button.bigvu-btn-link[disabled] {
    color: #c7c7c7;
    cursor: not-allowed;
}

.cursor-default {
    cursor: default !important;
}


/* disable all videojs spinners for vdm */

div.vdm-slides .vjs-seeking .vjs-loading-spinner,
div.vdm-slides .vjs-waiting .vjs-loading-spinner {
    display: none;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.text-button {
    cursor: pointer;
    color: #13b1fd;
    font-weight: 700;
    text-decoration: underline;
}


/* Tooltip custom classes */

.mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: unset !important;
    --mdc-plain-tooltip-supporting-text-color: unset !important;
}

.mat-mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
    --mdc-plain-tooltip-supporting-text-size: 14px !important;
    --mdc-plain-tooltip-supporting-text-weight: 400;
    --mdc-plain-tooltip-supporting-text-tracking: unset !important;
}

.mdc-tooltip__surface {
    overflow: visible;
    background: transparent !important;
}


.tooltip-custom-blue {
    width: fit-content;
    height: fit-content;
    border-radius: 7px;
    color: #6236ff !important;
    background-color: #efeafe !important;
    font-size: 12px !important;
    text-align: center;
    padding: 9px !important;
    position: relative;
    display: block;
    max-width: unset !important;
}

.tooltip-custom-blue::after {
    width: 0;
    height: 0;
    content: " ";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #efeafe;
    top: -9px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.mat-mdc-tooltip.tooltip-custom-blue {
    overflow: visible;
}

.tooltip-custom {
    width: fit-content;
    height: fit-content;
    border-radius: 7px;
    color: #6236ff !important;
    /*background-color: #efeafe !important;*/
    background-color: black !important;
    font-size: 12px !important;
    text-align: center;
    padding: 9px !important;
    position: relative;
    display: block;
    max-width: unset !important;
}

.tooltip-custom::after {
    width: 0;
    height: 0;
    content: " ";
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 9px solid #efeafe;
    top: -9px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.tooltip-custom-black-only {
    width: fit-content;
    height: fit-content !important;
    color: white !important;
    background-color: black !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    text-align: center;
    padding: 8px 12px !important;
    position: relative;
    display: block;
    border-radius: 10px !important;
}

.tooltip-custom-black {
    width: fit-content;
    height: fit-content !important;
    color: white !important;
    background-color: black !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    text-align: left;
    padding: 8px 12px !important;
    position: relative;
    display: block;
    border-radius: 10px !important;
}

.tooltip-custom-black::after {
    width: 0;
    height: 0;
    content: " ";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid black;
    top: 40% !important;
    position: absolute;
    transform: rotate(-90deg);
    left: -12px;
    margin: 0 auto;
}

.tooltip-custom-black-script-long {
    width: 340px !important;
    height: fit-content !important;
    color: white !important;
    background-color: black !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    text-align: left;
    padding: 8px 12px !important;
    position: relative;
    display: block;
    border-radius: 10px !important;
}

.tooltip-custom-black-script-long .mdc-tooltip__surface {
    max-width: 340px !important;
}

.tooltip-custom-black-script-long::after {
    width: 0;
    height: 0;
    content: " ";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid black;
    top: 44% !important;
    position: absolute;
    transform: rotate(-90deg);
    left: -12px;
    margin: 0 auto;
}

.socials-tooltip {
    top: -20px !important;
}

.tooltip-custom-composer {
    width: fit-content;
    height: fit-content;
    border-radius: 7px;
    color: #6236ff !important;
    background-color: #efeafe !important;
    font-size: 12px !important;
    text-align: center;
    padding: 9px !important;
    position: relative;
    display: block;
    max-width: unset !important;
}


.bio-tooltip {
    height: fit-content !important;
    top: -50% !important;
}

.error-tooltip {
    position: relative;
    display: block;
    top: -10px;
    background: linear-gradient(to left, #131313, #404040 0%) !important;
    border-radius: 10px !important;
    width: max-content !important;
    padding: 10px 8px !important;
    z-index: 1 !important;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1) !important;
    color: #fff !important;
    font-size: 12px !important;
}

.error-tooltip::after {
    width: 0;
    height: 0;
    content: " ";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 9px solid #404040;
    top: -9px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.tooltip-custom-black-take {
    width: fit-content;
    height: fit-content !important;
    color: white !important;
    background-color: black !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    text-align: left;
    padding: 8px 12px !important;
    position: relative;
    display: block;
    border-radius: 10px !important;
}

.tooltip-custom-black-take::after {
    width: 0;
    height: 0;
    content: " ";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid black;
    transform: rotate(180deg);
    position: absolute;
    left: 46%;
    margin: 0 auto;
    bottom: -10px;
}

.tooltip-custom-black-faq {
    width: fit-content;
    height: fit-content !important;
    color: white !important;
    background-color: black !important;
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    text-align: left;
    padding: 8px 12px !important;
    position: relative;
    display: block;
    border-radius: 10px !important;
}

.tooltip-custom-black-faq::after {
    width: 0;
    height: 0;
    content: " ";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid black;
    transform: rotate(180deg);
    position: absolute;
    left: 46%;
    margin: 0 auto;
    bottom: -5px;
}

.tooltip-custom-black-script-labels {
    width: 100% !important;
    height: fit-content !important;
    color: white !important;
    /* background-color: black !important; */
    background: linear-gradient(to right, rgba(64, 64, 64, 1), rgba(19, 19, 19, 1)); /* Left-to-right gradient */
    font-size: 11px !important;
    font-weight: 700 !important;
    line-height: 14px !important;
    text-align: center;
    padding: 8px 12px !important;
    position: relative;
    display: block;
    border-radius: 10px !important;
    margin-bottom: 5px;
}

.tooltip-custom-black-script-labels .mdc-tooltip__surface {
    max-width: 340px !important;
    font-weight: 700 !important;
    text-align: center !important;
}

.tooltip-custom-black-script-labels::after {
    width: 0;
    height: 0;
    content: " ";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid black;
    top: 100% !important;
    position: absolute;
    transform: rotate(-180deg) ; /* Changed to always center the arrow */
    margin: 0 auto;
    left: 50%; /* Use the variable here */
}

.tooltip-custom-white-script-labels-explanation {
    width: 100% !important;
    height: fit-content !important;
    /* background-color: black !important; */
    background: white !important;
    color: black !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    padding: 8px 12px !important;
    position: relative;
    display: block;
    border-radius: 10px !important;
    margin-bottom: 5px;
    box-shadow: -4px 5px 35.6px 0px rgba(0, 0, 0, 0.09);
    left: 50%;
}

.tooltip-custom-white-script-labels-explanation .mdc-tooltip__surface {
    min-width: 340px !important;
    max-width: 340px !important;
    text-align: left !important;
    color: black !important;
}


auto-complete-infinity-scroll.channel-branding-organizations-dropdown input,
auto-complete-infinity-scroll.deskmanager-organizations-dropdown input {
    height: 28px;
    line-height: 30px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    width: 157px !important;
    color: #909090;
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

auto-complete-infinity-scroll.channel-branding-organizations-dropdown button,
auto-complete-infinity-scroll.deskmanager-organizations-dropdown button {
    position: absolute;
    right: 0;
    top: 0;
}

auto-complete-infinity-scroll.channel-branding-organizations-dropdown md-autocomplete-wrap.md-whiteframe-z1.md-show-clear-button,
auto-complete-infinity-scroll.deskmanager-organizations-dropdown md-autocomplete-wrap.md-whiteframe-z1.md-show-clear-button {
    box-shadow: none;
}

.boards-holder {
    height: 100%;
    display: inline-block;
    background-color: #fff;
    min-width: 100%;
    min-height: 650px;
    margin-top: 10px;
}

.promo-header {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.32px;
}

.promo-sub-header {
    font-size: 16px;
    font-weight: normal;
    line-height: 2.5;
    letter-spacing: 0.26px;
}

.collaboration-features-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.collaboration-feature {
    width: 30%;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.22px;
    margin-right: 20px;
    margin-top: 12px;
    text-align: start;
}

.feature-title {
    font-weight: 600;
    color: #0075f8;
}

.promo-image img {
    object-fit: contain;
}

.promo-image {
    width: 50%;
    padding: 18px;
}

button {
    cursor: pointer;
    border: none;
    background: none;
}

.unclickable {
    cursor: text;
    pointer-events: none;
}


/* [mwlDraggable] {
    border-radius: 1px solid blue;
    background-color: white;
    width: 200px;
    height: 200px;
    position: relative;
    z-index: 99999999;
    float: left;
    margin-right: 10px;
    cursor: move;
} */


/* [mwlDraggable] {
    border-radius: 1px solid blue;
    background-color: white;
    width: 200px;
    height: 200px;
    position: relative;
    z-index: 99999999;
    float: left;
    margin-right: 10px;
    cursor: move;
}

.drop-over-active {
    border: 2px solid #11A9FE;
    background: #D3D9EC;
}

.drag-active {
    z-index: 9999999;
    display: none;
} */

.mat-mdc-select-panel {
    max-height: 100% !important;
}

label.validation-error-msg {
    color: red;
    text-align: start;
    width: 100%;
    margin: 7px 0;
    font-weight: 100;
}

.trash-icon {
    background-image: url(/img/trash-icon.svg);
    background-repeat: no-repeat;
    width: 11px;
    height: 15px;
    object-fit: contain;
    display: inline-block;
}

.info-icon {
    background-image: url(/img/info-icon.svg);
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    object-fit: contain;
    display: inline-block;
}

.menu-icon-asset {
    background-image: url(/img/3-dot-menu-black.svg);
    background-repeat: no-repeat;
    width: 6px;
    height: 16px;
    object-fit: contain;
    display: inline-block;
}
    
.folder-img {
    display: inline-block;
    position: relative;
    width: 14px;
    height: 9px;
    border-radius: 2px;
    border: solid 1px #0075f8;
    background-color: #ffffff;
    margin-right: 7px;
}

.folder-img::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 3px;
    border-radius: 1px;
    background-color: #0075f8;
    top: -2.8px;
    left: -0.7px;
}


/* BEGIN: Pagination overriden style */

li.pagination-previous a::before,
li.pagination-previous.disabled::before,
li.pagination-next a::after,
li.pagination-next.disabled::after {
    content: unset !important;
}

.pagination-controls-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 34px 0 20px;
}

.pagination-controls-container ul.ngx-pagination {
    background-color: rgba(123, 141, 177, .04);
    border-radius: 6px;
    padding: 7px 10px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

li.pagination-previous,
li.pagination-next,
li.pagination-previous.disabled,
li.pagination-next.disabled {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 12px;
    height: 12px;
    background-color: transparent !important;
}

li.pagination-previous.disabled {
    background-image: url("/img/left-arrow-grey.svg");
    transform: rotate(-180deg);
}

li.pagination-next.disabled {
    background-image: url("/img/left-arrow-grey.svg");
}

li.pagination-previous {
    background-image: url("/img/right-arrow-blue.svg");
}

li.pagination-next {
    background-image: url("/img/left-arrow-blue.svg");
}

.ngx-pagination li.current {
    background: linear-gradient(to left, #28b3f7 0%, #506aff 99%);
    width: 20px;
    height: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgb(68 129 253 / 26%);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 2px 3px;
}

.ngx-pagination a {
    color: #7b8db1 !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;
    height: 100%;
}

.ngx-pagination a:hover {
    background: rgba(123, 141, 177, .08) !important;
    border-radius: 4px;
}

li.pagination-previous.disabled,
li.pagination-next.disabled {
    pointer-events: none;
}

li.pagination-next a:hover {
    background: unset !important;
}

.ngx-pagination li:first-child {
    margin: 0 10px 0 0 !important;
}

.ngx-pagination li:last-child {
    margin: 0 0px 0 10px !important;
}

.ngx-pagination li {
    margin: 0 10px !important;
    display: block;
}


/* END: Pagination overriden style */

.customize-menu {
    background: linear-gradient(266deg, rgba(9, 21, 25, 1) 45%, rgba(64, 64, 64, 1) 100%) !important;
    border-radius: 10px !important;
}

.customize-menu-button {
    color: #fff !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.customize-menu-button .mat-mdc-menu-item span {
    color: #FFFFFF !important;
}

.edit-template-mat-panel-customize-menu {
    background: linear-gradient(266deg, rgba(9, 21, 25, 1) 45%, rgba(64, 64, 64, 1) 100%) !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
    margin-right: 12px !important;
}

/* STYLES FOR NEW CONFIRMATION DIALOG */
.change-confirmation-dialog-teams {
    width: 464px;
}

.change-confirmation-dialog-teams .mat-mdc-dialog-surface,
.change-confirmation-dialog .mat-mdc-dialog-surface,
.twin-confirmation-dialog .mat-mdc-dialog-surface,
.orphans-confirmation-dialog-content .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 15px !important;
}

.change-confirmation-dialog-teams .button-container,
.twin-confirmation-dialog .button-container,
.change-confirmation-dialog .button-container,
.orphans-confirmation-dialog-content .button-container {
    justify-content: center;
}

.change-confirmation-dialog-teams .button-container button {
    width: 196px;
    height: 44px;
    min-width: 196px;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
}

.twin-confirmation-dialog .button-container button,
.change-confirmation-dialog .button-container button,
.orphans-confirmation-dialog-content .button-container button {
    min-width: 96px;
    height: 36px;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
}

.twin-confirmation-dialog .button-container button#confirmation-action-button,
.twin-confirmation-dialog .button-container button#confirmation-cancel-button,
.change-confirmation-dialog .button-container button#confirmation-action-button,
.change-confirmation-dialog .button-container button#confirmation-cancel-button {
    background-color: #253658;
    opacity: .3;
}


.change-confirmation-dialog .button-container button#confirmation-submit-button {
    background-color: #00d096;
}

.twin-confirmation-dialog .button-container button#confirmation-submit-button {
    background-color: #00d096;
}

.change-confirmation-dialog-teams .button-container button#confirmation-submit-button {
    background-color: rgba(0, 171, 254, 1);

}

.orphans-confirmation-dialog-content .button-container button#confirmation-submit-button {
    background-color: #00abfe;
}

.twin-confirmation-dialog .button-container button#confirmation-action-button:hover,
.twin-confirmation-dialog .button-container button#confirmation-cancel-button:hover,
.change-confirmation-dialog .button-container button#confirmation-action-button:hover,
.change-confirmation-dialog .button-container button#confirmation-cancel-button:hover {
    background-color: #253658;
    opacity: .03;
    color: #fff !important;
}

.change-confirmation-dialog .button-container button#confirmation-submit-button:hover {
    background-color: #00d096;
    color: #fff !important;
    opacity: .5;
}

.twin-confirmation-dialog .button-container button#confirmation-submit-button:hover {
    background-color: #00d096;
    color: #fff !important;
    opacity: .5;
}

.change-confirmation-dialog-teams,
.twin-confirmation-dialog,
.change-confirmation-dialog {
    text-align: center;
    position: absolute !important;
    top: 280px;
}

.twin-confirmation-dialog .confirmation-msg{
    text-transform: none !important;
    font-size: 14px;
}

.change-confirmation-dialog-teams .confirmation-msg,
.change-confirmation-dialog .confirmation-msg {
    font-size: 14px;
}

.change-confirmation-dialog-teams confirmation-dialog .confirmation-header {
    text-align: center;
    font-size: 16px;
    max-width: 344px;
    margin: 0 auto;
}

.orphans-confirmation-dialog-content confirmation-dialog .confirmation-header {
    text-align: start;
    font-size: 16px;
    font-family: "Inter-Bold";
}

.change-confirmation-dialog-teams confirmation-dialog .confirmation-msg {
    text-align: center;
    font-size: 13px;
    max-width: 266px;
    margin: 12px auto 0;
}

.orphans-confirmation-dialog-content confirmation-dialog .confirmation-msg {
    text-align: start;
    margin: 0;
    font-size: 13px;
}


/*colorpicker fix*/

.color-picker .selected-color {
    height: 30px !important;
    top: 19px !important;
    width: 30px !important;
}

.color-picker .cursor {
    border: 3px solid rgb(255, 128, 171) !important;
}

.branding-color-picker-container {
    width: 200px;
    height: fit-content;
    padding: 16px 8px 12px 10px;
    border-radius: 10px;
    box-shadow: 0 0 16px 0 rgba(19, 19, 19, 0.14);
    background-color: var(--White);
}

.branding-page-global-color-circle {
    border-radius: 50%;
    width: 16px;
    min-width: 16px;
    margin-bottom: 5px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;
    border: solid 1px #f0f0f0;
}

.color-picker-placeholder {
    opacity: 0.08;
    cursor: pointer;
}

.branding-page-global-color-circle:hover {
    box-shadow: 0 0 2px 1px #ccc;
    border: none;
}

.branding-color-picker-subheader {
    font-weight: 600;
    font-family: 'Inter-Bold';
    color: #253658;
}

.color-picker {
    border: 0 !important;
}

.color-picker .saturation-lightness {
    border-radius: 10px !important;
}

.color-picker .hue,
.color-picker .value {
    border-radius: 6px !important;
    box-shadow: 0 0 16px 0 rgba(19, 19, 19, 0.14);;
}

.branding-add-color-to-palette-button {
    width: 20px;
    margin-bottom: 5px;
    height: 20px;
    margin-right: 4px;
    cursor: pointer;
}

.branding-add-color-to-palette-button:hover {
    filter: brightness(0.3) sepia(1) hue-rotate(140deg) saturate(6);
}

.color-picker-rgb-text-input {
    border-radius: 10px;
    background-color: rgba(123, 141, 177, .08);
    border: none;
    width: 80px;
    text-align: center;
    height: 26px;
    font-family: 'Inter-regular';
    font-size: 14px;
    color: var(--BlueBlack);
}

.color-picker .selected-color-background {
    height: 30px !important;
    margin-top: 3px;
    width: 30px !important;
}

.colorpicker_custom_color_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap
}

.colorpicker_custom_color_container::after {
    content: "";
    flex: auto
}

.three-dots-grey {
    display: block;
    background-image: url('/img/three-dots-grey.svg');
    background-repeat: no-repeat;
    width: 18px;
    height: 4px;
    object-fit: contain;
}

.chevron-header-menu:before {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: '';
    display: inline-block;
    height: 10px;
    position: relative;
    vertical-align: top;
    width: 10px;
    cursor: pointer;
    top: 10px;
    right: 5px;
    transform: rotate(135deg);
}

.organizations-menu-content {
    width: 170px !important;
    max-height: 256px !important;
}

.organizations-menu-content .mat-mdc-menu-content {
    padding: 0 !important;
}

.pages-menu-content {
    width: 180px !important;
    max-height: 256px !important;
}

.desk-menu-content {
    width: 174px;
}

.edit-organization-dialog-container {
    width: 500px;
}

@media only screen and (max-width: 1023px) {
    .desk-menu-content {
        width: 143px;
    }
}

@media only screen and (max-width: 767px) {
    .organizations-menu-content {
        width: 290px !important;
        max-width: none !important;
    }

    .desk-menu-content {
        width: 134px;
    }

    .edit-organization-dialog-container {
        width: 100%;
        max-width: none !important;
        margin: 0 5px;
    }
}

.menu-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    object-fit: contain;
}

.card-menu-icon {
    display: block;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.script-icon {
    background-image: url('/img/script-icon.svg');
}

.script-icon-active {
    background-image: url('/img/script-blue-icon.svg');
}

.composer-icon {
    background-image: url('/img/composer-icon.svg');
}

.composer-icon-active {
    background-image: url('/img/composer-blue-icon.svg');
}

.composer-menu-icon {
    background-image: url('/img/composer-menu-icon.svg');
}

.composer-menu-icon-active {
    background-image: url('/img/composer-menu-icon-blue.svg');
}

.sequence-icon {
    background-image: url('/img/sequence-icon.svg');
}

.sequence-icon-active {
    background-image: url('/img/sequence-icon-blue.svg');
}

.uploads-icon {
    background-image: url('/img/uploads.svg');
}

.uploads-icon-active {
    background-image: url('/img/uploads-active.svg');
}

.collections-icon {
    background-image: url('/img/collections-icon-black.svg');
}

.collections-icon-active {
    background-image: url('/img/collections-icon-blue.svg');
}

.channel-icon {
    background-image: url('/img/channel-icon.svg');
}

.duplicate-icon {
    background-image: url('/img/duplicate-black.svg');
}

.duplicate-icon-active {
    background-image: url('/img/duplicate-blue.svg');
}

.export-icon {
    background-image: url('/img/export-black.svg');
}

.test-icon {
    background-image: url(/img/page-black.svg);
}

.export-icon-active {
    background-image: url('/img/export-blue.svg');
}

.inspire-icon {
    background-image: url('/img/Inspiration.svg');
    width: 24px !important;
    height: 24px !important;
    margin: 0 0 0 0 !important;
}

.inspire-icon-active {
    background-image: url('/img/Inspiration.svg');
    width: 24px !important;
    height: 24px !important;
    margin: 0 0 0 0 !important;
}

.download-icon {
    background-image: url('/img/download-black.svg');
}

.download-icon-active {
    background-image: url('/img/download-blue.svg');
}

.delete-icon {
    background-image: url('/img/delete-black.svg');
}

.delete-icon-assets {
    background-image: url('/img/assets-v2/delete-icon.svg');
}

.edit-icon-assets {
    background-image: url('/img/assets-v2/edit-icon.svg');
}

.right-arrow-assets{
    content: url('/img/assets-v2/classic-arrow-icon.svg');
}

.ai-icon-assets{
    content: url('/img/assets-v2/ai-icon.svg');
}

.copy-text-assets{
    content: url('/img/assets-v2/copy-text-icon.svg');
}

.delete-icon-white {
    background-image: url('/img/delete-white.svg');
}

.delete-icon-active {
    background-image: url('/img/delete-blue.svg');
}

.edit-icon {
    background-image: url('/img/edit-black.svg');
}

.edit-icon-active {
    background-image: url('/img/edit-blue.svg');
}

.edit-icon-white {
    background-image: url('/img/edit-white.svg');
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    top: 10px;
}

.upload-icon-ai-generator{
    background-image: url('/img/uplaods-icon-cloud-black-outlined.svg');
    background-position: center;
    background-repeat: no-repeat;
}


.favorites-icon-white {
    background-image: url('/img/favorites-icon-white.svg');
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    top: 8px;
}

.move-icon-white {
    background: url("/img/icons/move-white.svg") no-repeat;
    top: 16px;
    left: 3px;
}

.white-image-icon {
    background: url("/img/white-image-icon.svg") no-repeat;
    top: 8px;
}

.white-switch-account-icon {
    background: url("/img/white-switch-account-icon.svg") no-repeat;
    top: 8px;
}

.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
}

.down-arrow {
    background-image: url("/img/down-arrow.svg");
}

.plus-icon-grey {
    background-image: url("/img/plus-icon-grey.svg");
}

.sequence-icon-grey {
    background-image: url("/img/sequence-icon.svg");
    filter: brightness(0) saturate(100%) invert(62%) sepia(10%) saturate(1106%) hue-rotate(182deg) brightness(88%) contrast(86%);
}

.delete-icon-red {
    background-image: url("/img/trash-icon.svg");
    filter: brightness(0) saturate(100%) invert(44%) sepia(41%) saturate(5016%) hue-rotate(329deg) brightness(109%) contrast(110%);
}

.edit-interactions-icon-white {
    background-image: url('/img/edit-interactions-icon-white.svg');
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
    top: 10px;
    left: 4px;
}

.embed-icon {
    background-image: url('/img/embed-black.svg');
}

.embed-icon-active {
    background-image: url('/img/embed-blue.svg');
}

.story-menu-page-icon {
    background-image: url('/img/page-black.svg');
}

.page-icon-white {
    background-image: url("/img/page-black.svg");
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(122deg) brightness(100%) contrast(96%);
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.story-menu-page-icon-active {
    background-image: url('/img/page-blue.svg');
}

.upload-small-icon {
    background-image: url('/img/upload-black-small.svg');
}

.upload-small-icon-active {
    background-image: url('/img/upload-blue-small.svg');
}

.upload-thumbnail-icon {
    background-image: url("/img/thumbnail_black.svg");
}

.upload-thumbnail-icon-active {
    background-image: url("/img/thumbnail_blue.svg");
}

.edit-labels-icon{
    width: 24px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url('/img/price-tag-icon-white.svg'); 
}

.three-dots-grey-vertical {
    background-image: url("/img/grey-3-dots.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    margin-bottom: -13px;
}

.three-dots-vertical-white {
    background-image: url("/img/white-3-dots.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    margin-bottom: -13px;
}

.trash-icon-white {
    background-image: url("/img/trash-icon-white.svg");
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
    top: 3px;
}

.plus-icon-white {
    background-image: url("/img/plus-icon-white.svg");
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    display: inline-block;
    position: relative;
    top: 3px;
}

.open-composer-icon-white {
    background-image: url("/img/open-composer-icon.svg");
}

.download-icon-white {
    background-image: url("/img/download-white-icon.svg");
}

.embed-icon-white {
    background-image: url("/img/embed-white-icon.svg");
}

.pages-icon-white {
    background-image: url("/img/pages-icon-white.svg");
}



.project-menu-content {
    background: linear-gradient(to left, #131313 0%, #404040 100%) !important;
}

.card-menu-option {
    background: linear-gradient(to left, #131313 0%, #404040 100%) !important;
    color: #fff !important;
    font-size: 12px !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.active-card-menu-option {
    background: rgba(123, 141, 177, .08) !important;
}

.embed-video-dialog-content {
    position: absolute !important;
    top: 190px;
    width: 450px;
}

.embed-video-dialog-content .mat-mdc-dialog-surface {
    padding: 15px !important;
    color: #444;
}

.coupon-info-dialog-content {
    width: 650px !important;
    max-height: 450px !important;
}

.coupon-info-dialog-content .mat-mdc-dialog-surface {
    max-width: unset;
    padding: 24px;
}

.loading-text {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
}

.select-dialog-content {
    width: 465px;
    min-width: 300px;
    max-width: 350px;
    height: 245px;
}

.select-dialog-content .mat-mdc-dialog-surface {
    padding: 10px !important;
    border-radius: 10px;
    position: relative !important;
}

.pages-user-research-dialog-content {
    width: 611px;
    min-width: 611px;
    max-width: 611px;
    height: 230px;
}

.pages-user-research-dialog-content .mat-mdc-dialog-surface {
    padding: 20px !important;
    border-radius: 15px !important;
    position: relative !important;
}

.collection-settings-menu {
    width: 223px !important;
}

.scale-down-center:hover {
    -webkit-animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-down-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
    }
}

@keyframes scale-down-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 19px;
    margin-bottom: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(123, 141, 177, 0.08);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    width: 16px;
    height: 13px;
    left: 4px;
    bottom: 3px;
    background-color: #00abfe;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: rgba(123, 141, 177, 0.08);
}

input:focus + .slider {
    box-shadow: 0 0 1px rgba(123, 141, 177, 0.08);
}

input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 10px;
}

.slider.round:before {
    border-radius: 10px;
}

.video-popup-dialog .mat-mdc-dialog-surface {
    border-radius: 10px;
    padding: 0;
}

.video-popup-details-dialog-container {
    padding: 14px;
}

.video-popup-details-dialog-container .mat-mdc-form-field {
    line-height: 1.5;
}

.templates-logo-background-wrapper {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 25px;
    margin: 18px;
    margin-top: 0;
}

.templates-options-title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #253658;
}

.horizontal-divider {
    border: 1px solid rgba(123, 141, 177, 0.3);
}

.file-name-subtitle {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7b8db1;
    padding-top: 5px;
}

.swiper-component-header {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #253658;
    padding-bottom: 8px;
}

:root {
    --Primary: #00abfe;
    --Greenz: #00d096;
    --Black: #000000;
    --White: #ffffff;
    --Black-60: rgba(0, 0, 0, 0.6);
    --Black-30: rgba(0, 0, 0, 0.3);
    --Black-8: rgba(0, 0, 0, 0.08);
    --Pinky: #ff86bc;
    --Redish: #fd616d;
    --Redish-60: rgba(253, 97, 109, 0.6);
    --Redish-30: rgba(253, 97, 109, 0.3);
    --Redish-8: rgba(253, 97, 109, 0.08);
    --White-60: rgba(255, 255, 255, 0.6);
    --White-30: rgba(255, 255, 255, 0.3);
    --White-8: rgba(255, 255, 255, 0.08);
    --Primary-60: rgba(0, 171, 254, 0.6);
    --Primary-30: rgba(0, 171, 254, 0.3);
    --Primary-8: rgba(0, 171, 254, 0.08);
    --DeadBlue: #7b8db1;
    --DeadBlueOpacity08: #f5f6fa;
    --DeadBlue-60: rgba(123, 141, 177, 0.6);
    --DeadBlue-8: rgba(123, 141, 177, 0.08);
    --BlueBlack: #253658;
    --Royal: #4481fd;
    --colors-dead-blue-30: rgba(123, 141, 177, 0.3);
    --colors-black-blue-8: rgba(37, 54, 88, 0.08);
    --colors-black-blue-30: rgba(37, 54, 88, 0.3);
    --colors-black-blue-70: rgba(37, 54, 88, 0.6);
    --colors-greenz-60: rgba(0, 208, 150, 0.6);
    --colors-purple: #aa58ee;
    --blue-gradient: linear-gradient(to left, #28b3f7 1%, #506aff);
    --grey-gradient: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), linear-gradient(to left, #28b3f7 99%, #506aff 1%);
}


/* color-picker */

.theme-editor-text-color-picker {
    /*appearance: none;*/
    width: 22px;
    height: 22px;
    border-radius: 50%;
    padding: 0;
    overflow: hidden;
    border: 0;
    box-shadow: 0 3px 5px 0 rgba(188, 188, 188, 0.3);
    cursor: pointer;
}

.color-picker_container {
    position: relative;
    width: 36px;
    height: 36px;
}

.theme-editor-color-picker-tooltip {
    position: absolute;
    top: 33px;
    left: -79px;
    z-index: 10;
}

.theme-editor-color-picker-tooltip::before {
    content: '';
    position: absolute;
    top: -9px;
    left: 45%;
    width: 0;
    height: 0;
    border-top: solid 9px white;
    border-left: solid 7px transparent;
    border-right: solid 7px transparent;
    transform: rotate(180deg);
}

.font-dropdown-title {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7b8db1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-material-input {
    width: 150px;
    height: 46px;
    background-color: rgba(123, 141, 177, 0.08);
    border-radius: 10px;
    padding-left: 5px;
    padding-top: 5px;
}


/* BEGIN: Dialogs */

.dialog-close-button-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.dialog-close-button {
    background-image: url("/img/exit-icon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
}

.dialog-title {
    text-align: center;
    font-size: 18px;
    font-family: 'Inter-Bold';
    color: #253658;
}


.welcome-device-dialog {
    width: 100%;
    height: 100vh;
    max-width: unset !important;
}

.welcome-device-dialog .mat-mdc-dialog-surface {
    padding: 0 !important;
}


/* END: Dialogs */

.select-dialog-menu-content {
    width: 333px;
    max-height: 400px !important;
    max-width: unset !important;
    border-radius: 10px !important;
    overflow-y: scroll !important;
}

.select-dialog-menu-option {
    font-size: 12px !important;
    color: #7b8db1 !important;
    padding: unset !important;
    margin: 0 auto;
    width: 95% !important;
    border-radius: 10px;
}

.select-dialog-menu-option-active {
    background-color: rgba(123, 141, 177, 0.08) !important;
}

.system-project-icon {
    background-image: url("/img/system-banner.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 45px;
    height: 15px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
}

.select-dialog-content {
    width: 465px;
    min-width: 300px;
    max-width: 350px;
    height: 245px;
    position: relative !important;
}

@media only screen and (max-width: 767px) {
    .select-dialog-menu-content {
        position: relative;
        width: 213px;
    }
}

.project-members-edit-dialog-content,
.story-presenters-edit-dialog-content {
    width: 610px;
    height: 442px;
    position: absolute !important;
    top: 139px !important;
    border-radius: 10px;
}

.project-members-edit-dialog-content .mat-mdc-dialog-surface,
.story-presenters-edit-dialog-content .mat-mdc-dialog-surface {
    padding: 0px !important;
    border-radius: 10px !important;
}

.black-menu {
    background: linear-gradient(to left, #131313 0%, #404040 90%) !important;
    border-radius: 10px !important;
    width: 143px;
    padding: 0 10px !important;
}

.black-menu-option {
    padding: 4px !important;
    font-size: 12px !important;
    color: #fff !important;
    margin: 0 !important;
    border-radius: 10px !important;
    height: 36px !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.black-menu-option-active {
    background-color: rgba(255, 255, 255, 0.08) !important;
}

.black-menu-option span.option-icon {
    position: absolute;
    right: 0;
}

.green-checkmark {
    background-image: url("/img/green-checkmark.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 13px;
    height: 13px;
    display: inline-block;
}

.form-select-menu-content {
    width: 280px !important;
    max-width: unset !important;
    border-radius: 10px !important;
}

.form-select-menu-content button.mat-mdc-menu-item,
.form-select-menu-content .mat-mdc-option {
    font-size: 12px !important;
    color: #7b8db1 !important;
    height: 36px !important;
    padding: 0 10px;
}

.web-onboarding-select-menu-content {
    width: 280px !important;
    height: 200px !important;
    max-width: unset !important;
    border-radius: 10px !important;
}

.post-card-menu {
    width: 180px !important;
    border-radius: 10px !important;
}

.post-card-menu button.mat-mdc-menu-item,
.post-card-menu .mat-mdc-option {
    font-size: 14px;
    color: #7b8db1 !important;
}

.web-onboarding-select-menu-content button.mat-mdc-menu-item,
.web-onboarding-select-menu-content .mat-mdc-option {
    font-size: 12px !important;
    color: #7b8db1 !important;
    height: 36px !important;
    padding: 0 10px;
}

.affiliate-request-dialog-content {
    width: 580px;
}

.affiliate-request-dialog-content .mat-mdc-dialog-surface {
    border-radius: 8px;
}

@media only screen and (max-width: 1023px) {
    .affiliate-request-dialog-content {
        width: 100%;
        margin: 0 10px;
        max-width: 450px !important;
    }
}

.input-error-msg {
    font-size: 11px;
    margin-left: 5px;
    color: #ff4d5b;
}

.details-dialog-content {
    max-width: unset;
    width: 420px;
}

.details-dialog-content .mat-mdc-dialog-surface {
    padding: 10px !important;
}


.ai-music-dialog-content {
    width: 516px;
    height: 563px;
}

.ai-music-dialog-content .mat-mdc-dialog-surface {
    padding: 24px !important;
    border-radius: 20px !important;
}

.dialog-24p-20br .mat-mdc-dialog-surface {
    padding: 24px !important;
    border-radius: 20px !important;
}

.admin-expert-edit-dialog-content {
    max-width: unset;
    height: 660px;
    width: 420px;
}

.admin-expert-edit-dialog-content .mat-mdc-dialog-surface {
    padding: 10px !important;
}

.admin-notification-edit-dialog-content {
    max-width: unset;
    height: 660px;
    width: 420px;
}

.admin-notification-edit-dialog-content .mat-mdc-dialog-surface {
    padding: 10px !important;
}

.admin-versionc-edit-dialog-content {
    max-width: unset;
    height: 530px;
    width: 420px;
}

.admin-versionc-edit-dialog-content .mat-mdc-dialog-surface {
    padding: 10px !important;
    border-radius: 10px !important;
}


.sudo-dialog-content {
    max-width: unset;
    width: 380px;
}

.sudo-dialog-content .mat-mdc-dialog-surface {
    padding: 10px !important;
}

.event-rerun-confirmation-content {
    max-width: unset !important;
    width: 360px;
}

.event-rerun-confirmation-content .mat-mdc-dialog-surface {
    padding: 10px !important;
    color: #253658;
}

.osano-cm-widget {
    display: none;
}

.pricing-offers-dialog-container {
    width: 780px;
    min-width: 780px;
}

.pricing-offers-dialog-container .mat-mdc-dialog-surface {
    padding: 16px !important;
    border-radius: 10px !important;

}

@media screen and (max-width: 780px) {
    .pricing-offers-dialog-container {
        width: 90% !important;
        min-width: unset !important;
        max-width: 100vw !important;
        overflow-y: scroll !important;
    }
}

.mat-mdc-menu-panel {
    min-height: unset !important;
}

.edit-organization-dialog-container .mat-mdc-dialog-surface {
    padding: 15px !important;
    border-radius: 10px !important;
}

.admin-marketing-tasks-filter-container {
    width: 320px;
    height: 450px;
}

.admin-marketing-tasks-filter-container .mat-mdc-dialog-surface {
    padding: 10px !important;
    border-radius: 10px !important;
}

.email-task-creation-content {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
}

.email-task-creation-content .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 10px 20px !important;
}

.dialog-container-large {
    max-width: 418px !important;
}

.dialog-container-large .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    text-align: center;
    padding: 0 !important;
}

.onboarding-dialog-content {
    width: 486px;
    height: 488px;
    max-width: 486px !important;
}

.onboarding-dialog-content .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 0 !important;
    overflow: unset;
}


.web-onboarding-dialog-content {
    width: 660px;
    height: 480px;
    max-width: 660px !important;
}

.web-onboarding-dialog-content .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 0 !important;
    overflow: unset;
}

.portable-register-dialog-content {
    width: 660px;
    max-width: 660px !important;
}

.portable-register-dialog-content .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 0 !important;
}

.access-denied-dialog-content {
    max-width: 610px !important;
    height: 260px;
}

.access-denied-dialog-content .mat-mdc-dialog-surface {
    padding: 0 !important;
    border-radius: 10px !important;
}

.white-backdrop {
    background: linear-gradient(to left, #f4fbfe 1%, #f6f8ff 99%);
    position: relative;
}

.white-backdrop::after {
    content: url("/img/brand-logo.svg");
    position: absolute;
    top: 18px;
    left: 18px;
    width: 52px;
    height: 34px;
}

.appsumo-subscription-dialog-content {
    width: 544px;
    min-width: 300px;
}

.appsumo-subscription-dialog-content .mat-mdc-dialog-surface {
    padding: 0 !important;
    border-radius: 10px !important;
    position: relative !important;
}

.appsumo-subscription-dialog-content .dialog-close-button-container {
    position: absolute;
    top: 5px;
    right: 5px;
}

.delete-domain-dialog {
    width: 356px !important;
    max-width: 100% !important;
}

.delete-domain-dialog .mat-mdc-dialog-surface {
    padding: 12px !important;
    border-radius: 10px !important;
    position: relative !important;
}

.delete-domain-dialog confirmation-dialog {
    color: #253658 !important;
    text-align: center !important;
}


.expert-public-profile-dialog {
    width: 309px !important;
    max-width: 100% !important;
}

.expert-public-profile-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 14px !important;
    position: relative !important;
}

.expert-public-profile-dialog confirmation-dialog {
    color: #253658 !important;
    text-align: center !important;
}

.expert-public-profile-dialog {
    width: 309px !important;
    max-width: 100% !important;
}

.expert-public-profile-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    position: relative !important;
}

.expert-public-profile-dialog confirmation-dialog {
    color: #253658 !important;
    text-align: center !important;
}

.expert-public-profile-dialog confirmation-dialog .button-container {
    justify-content: center !important;
}

.expert-public-profile-dialog confirmation-dialog .button-container button {
    border-radius: 10px;
    width: 130px;
    height: 36px;
    font-family: 'Inter-Bold';
    font-size: 12px;
}

.expert-public-profile-dialog confirmation-dialog .button-container button#confirmation-cancel-button {
    background-color: rgba(123, 141, 177, 0.08);
    color: #7b8db1;
}

.expert-public-profile-dialog confirmation-dialog .button-container button#confirmation-submit-button {
    background-color: #4481FD;
    color: #FFFFFF;
}

.expert-public-profile-dialog confirmation-dialog .confirmation-msg {
    font-size: 14px;
}

.expert-public-profile-dialog confirmation-dialog .button-container button.bigvu-btn-dismiss:hover {
    color: #7b8db1 !important;
}

.expert-public-profile-dialog confirmation-dialog .button-container button.bigvu-apply-dismiss:hover {
    color: #fff !important;
}


.hire-experts-dialog-content {
    width: 680px;
}

.hire-experts-dialog-content .mat-mdc-dialog-surface {
    padding: 30px 25px 30px 30px !important;
    border-radius: 10px !important;
}

.settings-dialog-content {
    width: 650px;
    min-width: 300px;
    min-height: 300px;
}

.settings-dialog-content .mat-mdc-dialog-surface {
    padding: 0px !important;
    border-radius: 10px;
    position: relative !important;
}


.linkedInMode-dialog-content {
    max-width: 624px !important;
}

.linkedInMode-dialog-content .mat-mdc-dialog-surface {
    border-radius: 20px !important;
    padding: 32px 38px !important;
}

.activation-dialog-content {
    max-width: 418px !important;
}

.activation-dialog-content .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 36px !important;
}

@media (max-width: 720px) {
    .activation-dialog-content {
        max-width: 280px !important;
    }

}

::ng-deep #medium-editor-toolbar-1 .medium-editor-toolbar-form .medium-editor-toolbar-input a {
    font-size: 0 !important;
}

::ng-deep .medium-editor-toolbar-input a.medium-editor-toolbar-save {
    width: 28px;
    height: 28px;
    position: relative;
}

::ng-deep .medium-editor-toolbar-input a.medium-editor-toolbar-save::after {
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("/img/v-icon-white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

::ng-deep .medium-editor-toolbar-input a.medium-editor-toolbar-close::after {
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("/img/exit-icon-white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}


.export-video-success-dialog {
    max-width: 443px !important;
    max-height: 424px !important;
}

.export-video-success-dialog .mat-mdc-dialog-surface {
    padding: 14px 30px 16px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px !important;
}

.social-accounts-dialog-container {
    width: 95%;
    height: 85%;
    max-width: unset !important;
    position: relative !important;
}

.social-accounts-dialog-container .mat-mdc-dialog-surface {
    border-radius: 10px !important;
}

.single-user-dropdown-menu-container {
    max-width: unset !important;
    width: 496px !important;
    max-height: 200px !important;
}

.admin-dropdown-menu-container {
    max-width: unset !important;
    width: 145px !important;
    max-height: 200px !important;
}

.assets-folders-menu-content{
    border-radius: 10px !important;
}

.assets-folders-menu-content .mat-menu-item,
.assets-folders-menu-content .mat-mdc-menu-item-text {
    height: 40px;
}


.expert-dropdown-menu-container {
    max-width: unset !important;
    width: 142px !important;
    max-height: 200px !important;
    margin-top: 2px !important;
}

.ai-rewrite-dropdown-container {
    max-width: unset !important;
    width: 250px !important;
    left: -6px;
    max-height: 260px !important;
    margin-top: 3px !important;
    border-radius: 10px !important;
}


.select-lang-course-dropdown-length {
    width: 200px !important;
}

.ai-rewrite-dropdown-length {
    max-width: unset !important;
    width: 148px !important;
    left: -38px;
    max-height: 260px !important;
    margin-top: 8px !important;
    border-radius: 10px !important;
}

.ai-rewrite-dropdown-tone .mat-mdc-menu-content,
.ai-rewrite-dropdown-length .mat-mdc-menu-content {
    padding: 0;
}

.ai-rewrite-dropdown-tone .mat-mdc-menu-item.mdc-list-item:not(:first-child),
.ai-rewrite-dropdown-length .mat-mdc-menu-item.mdc-list-item:not(:first-child) {
    border-top: 1px solid rgba(123, 141, 177, 0.30);
}

.ai-rewrite-dropdown-tone {
    max-width: unset !important;
    width: 196px !important;
    left: -38px;
    max-height: 300px !important;
    margin-top: 8px !important;
    border-radius: 10px !important;
}

.admin-dialogs-select-menu {
    max-width: unset !important;
    width: 322px !important;
    max-height: 200px !important;
}

.topics-select-menu {
    max-width: unset !important;
    width: 180px !important;
    max-height: 200px !important;
}

.desk-top-select-menu {
    max-width: unset !important;
    width: 180px !important;
    max-height: 200px !important;
}

.email-task-dropdown-menu {
    max-width: unset !important;
    width: 320px !important;
    max-height: 200px !important;
}

.email-task-dropdown-menu.mat-mdc-select-panel {
    width: 320px !important;
    min-width: unset !important;
    max-height: 200px !important;
    margin-top: 32px;
    margin-left: 10px;
    padding: 8px 0px;
}

.email-task-dropdown-menu.mat-select-panel mat-option {
    margin-bottom: 4px !important;
}


.email-task-auto-timing-dropdown-menu {
    max-width: unset !important;
    width: 150px !important;
    max-height: 200px !important;
}

.email-task-auto-timing-dropdown-menu.mat-mdc-select-panel {
    width: 150px !important;
    min-width: unset !important;
    max-height: 200px !important;
    margin-top: 32px;
    margin-left: 10px;
    padding: 8px 0px;
}

.email-task-auto-timing-dropdown-menu.mat-select-panel mat-option {
    margin-bottom: 4px !important;
}

.calendar-hours-dropdown {
    max-width: unset !important;
    width: 40px !important;
    max-height: 200px !important;
}

.calendar-hours-dropdown.mat-mdc-select-panel {
    width: 40px !important;
    min-width: unset !important;
    max-height: 200px !important;
    margin-top: 32px;
    margin-left: 10px;
    padding: 4px 0px;
}

.story-menu-container {
    max-width: 300px !important;
    max-height: 300px !important;
}

.digest-dialog-content {
    max-width: unset !important;
    width: 840px;
    height: 472px;
}

.media-asset-type-dropdown {
    border-radius: 10px;
    top: 2px;
    /* max-width: unset !important; */
    width: 300px !important;
    max-height: 260px !important;
    min-width: unset !important;
}

.media-l-header-dropdown {
    top: 2px;
    max-width: unset !important;
    width: 172px !important;
    max-height: 200px !important;
    min-width: unset !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #253658 !important;
}


.media-folder-dialog-dropdown {
    top: 2px;
    max-width: unset !important;
    width: 264px !important;
    max-height: 200px !important;
    min-width: unset !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #253658 !important;
}

.asset-mediabox-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;

    aspect-ratio: 1/1;
}

.asset-select-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}


.asset-mediabox-edit-icon {
    background-image: url('/img/mediaAssets/dropdown-edit.svg');
}

.asset-mediabox-move-icon {
    background-image: url('/img/mediaAssets/dropdown-folder.svg');
}

.asset-mediabox-duplicate-icon {
    background-image: url('/img/mediaAssets/dropdown-duplicate.svg');
}

.asset-mediabox-move-to-workspace-icon {
    background-image: url('/img/mediaAssets/dropdown-workspace.svg');
}

.asset-mediabox-select-icon {
    background-image: url('/img/mediaAssets/dropdown-check.svg');
}

.asset-mediabox-delete-icon {
    background-image: url('/img/assets-v2/delete-icon.svg');
}

.asset-select-folder-icon {
    background-image: url('/img/mediaAssets/folder-select.svg');
}

.asset-select-folder-icon-active {
    background-image: url('/img/mediaAssets/folder-select-active.svg');
}

.asset-select-image-icon {
    background-image: url('/img/mediaAssets/image.svg');
}

.asset-select-image-icon-active {
    background-image: url('/img/mediaAssets/image-active.svg');
}

.asset-select-video-icon {
    background-image: url('/img/mediaAssets/video.svg');
}

.asset-select-video-icon-active {
    background-image: url('/img/mediaAssets/video-active.svg');
}

.asset-select-audio-icon {
    background-image: url('/img/mediaAssets/music.svg');
}

.asset-select-audio-icon-active {
    background-image: url('/img/mediaAssets/music-active.svg');
}

.upload-assetbar-option {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.upload-dropbox {
    background-image: url('/img/mediaAssets/dropbox-upload.svg');
}

.upload-instagram {
    background-image: url('/img/mediaAssets/instagram-upload.svg');
}

.upload-google {
    background-image: url('/img/mediaAssets/drive-upload.svg');
}

.upload-more {
    background-image: url('/img/mediaAssets/more-upload.svg');
}

@media only screen and (max-width: 767px) {
    .digest-dialog-content {
        width: unset;
        height: unset;
    }
}

.digest-dialog-content .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 0 !important;
    overflow: hidden;
}

.asset-library-dialog-content {
    width: 520px;
    height: 100%;
    position: absolute !important;
    top: 10%;
}

.asset-library-dialog-content .mat-mdc-dialog-surface {
    padding: 10px !important;
    border-radius: 10px;
}

.ai-custom-style-tone-dialog-content {
    width: 840px;
    height: 474px;
}

.ai-custom-style-tone-dialog-content .mat-mdc-dialog-surface {
    padding: 32px 24px !important;
    border-radius: 20px !important;
    overflow: hidden;
}

.asset-info-dialog-container {
    width: 800px;
    max-width: 800px !important;
}

.asset-info-dialog-container .mat-mdc-dialog-surface {
    padding: 16px;
    border-radius: 10px !important;
    overflow: hidden;
}


.asset-folder-dialog-container {
    width: 400px;
    height: 240px;
    max-width: 400px !important;
}

.asset-folder-dialog-container .mat-mdc-dialog-surface {
    padding: 16px;
    border-radius: 10px !important;
}

@media only screen and (max-width: 767px) {
    .asset-info-dialog-container,
    .asset-folder-dialog-container {
        width: 90%;
        max-width: unset !important;
    }
}

@media only screen and (max-width: 425px) {
    .asset-info-dialog-container,
    .asset-folder-dialog-container {
        width: 100%;
        height: 100%;
    }
}

.asset-info-menu {
    border-radius: 10px;
    padding: 12px;
}

.asset-menu-icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: block;
    margin-right: 6px;
}

.asset-menu-option {
    display: flex !important;
    align-items: center !important;
    color: #7b8db1 !important;
    font-size: 14px !important;
    border-radius: 10px !important;
    padding: 8px !important;
}

.asset-menu-option-active {
    background-color: rgba(123, 141, 177, 0.08) !important;
    color: #4481fd !important;
}

.asset-delete {
    color: #ff4d5b !important;
}

.blue-filter {
    filter: brightness(0) saturate(100%) invert(39%) sepia(96%) saturate(2040%) hue-rotate(208deg) brightness(104%) contrast(98%) !important;
}

.folder-icon {
    background-image: url("/img/folder-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
}


.arrow-icon {
    background-image: url("/img/arrow-icon.svg");
    object-fit: contain;
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    margin: 0 0 0 16px;
}


.media-library-folders-menu {
    width: 100%;
    position: absolute !important;
}

.folder-edit-dialog {
    width: 400px !important;
}

.folder-edit-dialog .mat-mdc-dialog-surface {
    padding: 10px !important;
    border-radius: 10px !important;
}


.asset-filter-dialog {
    width: 490px !important;
    height: auto;
}

.asset-filter-dialog .mat-mdc-dialog-surface {
    padding: 10px !important;
    border-radius: 20px !important;
}

/*Thumbnail editor*/
.thumbnail-editor-select-menu {
    width: 200px !important;
    height: 200px !important;
}

.thumbnail-dialog-container .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 0 !important;
    overflow: unset;
}

.thumbnail-dialog-container {
    width: 95%;
    height: 95%;
    min-width: 1024px;
    min-height: 680px;
    max-width: unset !important;
    position: relative !important;
}

@media screen and (max-height: 1080px) {
    .thumbnail-dialog-container {
        width: 100%;
        height: 100%;
        min-width: 1024px;
        min-height: 680px;
        max-width: unset !important;
        position: relative !important;
    }
}


.thumbnail-editor-confirm-dialog {
    width: 660px;
    height: 271px;
    max-width: unset !important;
}

.thumbnail-editor-confirm-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
}

.remove-mat-dialog-padding .mat-mdc-dialog-surface {
    padding: 0;
    border-radius: 10px !important;
}

.sidebar-modal-dialog .mat-mdc-dialog-surface {
    box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.12) !important;
    border-radius: 20px !important;
}

.sidebar-modal-dialog-backdrop-bg {
    background: rgba(0, 0, 0, 0.30);
    margin-left: 80px;
    margin-top: 67px;
}
.assets-uploads-library-modal-dialog .mat-mdc-dialog-surface {
    box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.12) !important;
    border-radius: 20px !important;
}



.intro-outro-editor-unsaved-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
}

.intro-outro-editor-unsaved-dialog {
    text-align: center;
    position: absolute !important;
    top: 280px;
}

.composer-studio-apply-all-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
}

.composer-studio-apply-all-dialog {
    text-align: center;
    position: absolute !important;
    top: 280px;
}

.intro-outro-business-editor-dialog .mat-mdc-dialog-surface {
    border-radius: 6px !important;
    width: 650px !important;
    background-color: #f5f6f9 !important;
    height: 80vh !important;
}

.grey-triangle {
    background-image: url("/img/grey-triangle.svg");
    background-position: center;
    background-size: contain;
    width: 24px;
    height: 24px;
    display: block;
}

.blueblack-triangle { /* #253658 */
    background-image: url("/img/grey-triangle.svg");
    background-position: center;
    background-size: contain;
    width: 24px;
    height: 24px;
    display: block;
    filter: brightness(0) saturate(100%) invert(15%) sepia(46%) saturate(1177%) hue-rotate(188deg) brightness(93%) contrast(84%);
}

.grey-triangle-active-ai {
    background-image: url("/img/white-triangle.svg");
    background-position: center;
    background-size: contain;
    width: 24px;
    height: 24px;
    display: block;
}

.grey-triangle-active-ai,
.blue-big-arrow.opened,
.grey-down-arrow.opened,
.grey-triangle.opened,
.blueblack-triangle.opened {
    transform: rotate(180deg);
}


.story-menu-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 8px 4px 4px 3px;
}

.assets-menu-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 8px 4px 4px 3px;
}

.assets-modal-menu-icon{
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
}

.project-card-menu-option {
    display: flex !important;
    padding: 0px 10px !important;
    font-size: 14px;
}

.project-card-menu-option-active {
    display: flex !important;
    padding: 0px 10px !important;
    font-size: 14px;
    background-color: #ebf8ff !important;
    color: #4481FD !important;
}

.projects-menu-container {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
    width: 180px;
}

.projects-menu-container.mat-mdc-select-panel {
    max-height: unset !important;
    position: absolute;
    top: 10px;
    left: -50px;
    width: 180px;
}

@media only screen and (max-width: 549px) {
    .projects-menu-container.mat-mdc-select-panel {
        left: 30px;
        top: 40px;
    }
}


.projects-menu-container.mat-select-panel mat-optgroup label {
    color: rgba(123, 141, 177, 0.6);
    font-weight: normal;
    padding: 0 45px;
    font-size: 14px;
    margin: 0 !important;
    height: 32px !important;
}

.projects-menu-container.mat-select-panel mat-optgroup mat-option {
    color: #253658;
    font-size: 14px;
    padding: 0 12px !important;
    height: 32px !important;
    margin: 12px 0 !important;
}

.projects-menu-container.mat-select-panel mat-optgroup mat-option.mat-selected {
    background-color: #fff !important;
}

.projects-menu-container.mat-select-panel mat-optgroup mat-option:hover {
    background-color: rgba(0, 0, 0, .04) !important;
}

.projects-menu-container.mat-select-panel mat-optgroup mat-option .blue-checkmark {
    background-image: url("/img/blue-checkmark-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 16px;
    height: 16px;
}

.blue-checkmark {
    background-image: url("/img/blue-checkmark-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 16px;
    height: 16px;
}

.projects-filter-option {
    color: #253658 !important;
    font-size: 14px !important;
    display: flex !important;
    align-items: center;
    padding: 0 0 0 5px !important;
}

.selected-project-filter-option-icon-container {
    width: 16px;
    margin-right: 8px;
}

.selected-project-filter-option-icon-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.selected-project-filter-option-icon-container .option-name {
    width: 100%;
}

.filter-menu-content .option-active {
    background-color: #ebf8ff !important;
    color: #00abfe !important;
}

.course-certificate-content {
    width: 95%;
    height: 95%;
    max-width: unset !important;
    position: relative !important;
}

.course-certificate-content .mat-mdc-dialog-surface {
    padding: 16px !important;
    border-radius: 20px !important;
    box-shadow: none !important;
    overflow: unset;
}

@media only screen and (max-width: 768px) {
    .course-certificate-content {
        width: 100%;
        max-width: unset !important;
        min-height: 100vh !important;
        height: fit-content !important;
        overflow: auto !important;
    }

    .course-certificate-content .mat-mdc-dialog-surface {
        border-radius: unset !important;
    }

    .web-onboarding-dialog-content {
        max-width: 100% !important;
    }
}

.course-edit-dialog-content {
    width: 670px;
}

.course-edit-dialog-content .mat-mdc-dialog-surface {
    padding: 12px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
}

.fake-progress-dialog-content {
    width: 500px;
}

.fake-progress-dialog-content .mat-mdc-dialog-surface {
    padding: 12px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
}

@media screen and (max-width: 720px) {
    .fake-progress-dialog-content {
        width: 90%;
    }
}

.preference-lang-dialog {
    max-width: 840px !important;
    width: 90%;
    height: 474px;
}

@media screen and (max-height: 768px) {
    .preference-lang-dialog {
        height: 560px;
    }
}

.preference-lang-dialog .mat-mdc-dialog-surface {
    border-radius: 20px !important;
    padding: 24px 18px !important;
    overflow: hidden !important;
}

.edit-course-lesson-dialog-content {
    width: 600px;
}

.edit-course-lesson-dialog-content .mat-mdc-dialog-surface {
    padding: 12px !important;
    border-radius: 10px !important;
}


.edit-language-course-lesson-dialog-content {
    width: 500px;
    height: 320px;
}

.edit-language-course-lesson-dialog-content .mat-mdc-dialog-surface {
    padding: 12px !important;
    border-radius: 10px !important;
}

.account-menu-content {
    width: 140px;
}

.account-menu-content button.mat-mdc-menu-item.active-account-option {
    background-color: rgba(0, 0, 0, 0.04);
}

.post-menu-content {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
    max-width: unset !important;
}

.post-menu-content button.mat-mdc-menu-item {
    line-height: unset !important;
}

.post-menu-option {
    font-size: 14px !important;
    color: #253658 !important;
    display: flex !important;
    align-items: center;
}

.post-menu-option-red {
    color: #FF4D5B !important;
}

.post-menu-option:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.post-menu-option-icon {
    width: 32px;
    height: 32px;
    display: inline-block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
}

.analytics-option {
    background-image: url("/img/analytics-icon.svg");
}

.channel-delete-option {
    background-image: url("/img/trash-icon-red-small.svg");
}

.reconnect-option {
    background-image: url("/img/refresh-icon-grey-small.svg");
}

.account-permission-menu-content {
    width: 160px;
    box-shadow: 0px 0px 40px rgba(0 0 0 / 10%) !important;
    border-radius: 10px !important;
}

.account-permission-menu-content button.mat-mdc-menu-item {
    font-size: 14px;
    color: #7b8db1;
}

.account-permission-active-option {
    position: relative !important;
    background: unset !important;
}

.account-permission-active-option::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 100%;
    background: rgba(123, 141, 177, 0.08) !important;
    border-radius: 10px;
}

.social-account-menu-content {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
}

.permissions-menu-content {
    width: 136px;
}

.workspace-filter-menu-content {
    width: 186px !important;
    max-width: unset !important;
    max-height: 256px !important;
    margin-top: 2px;
}

.permissions-menu-content,
.workspace-menu-content {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, .1) !important;
    border-radius: 10px !important;
}

.permissions-menu-content button.mat-mdc-menu-item {
    padding: unset !important;
}

.workspace-menu-content button.mat-mdc-menu-item {
    padding: 0 12px !important;
}

.workspace-menu-content button.mat-mdc-menu-item:not(:last-child) {
    border-bottom: 1px solid rgba(123, 141, 177, 0.3);
}


.permissions-menu-content button.mat-mdc-menu-item {
    padding: unset !important;
}

.workspace-menu-content button.mat-mdc-menu-item {
    padding: 0 12px !important;
}

.workspace-menu-content button.mat-mdc-menu-item:not(:last-child) {
    border-bottom: 1px solid rgba(123, 141, 177, 0.3);
}

.collaborate-details-dialog,
.workspace-details-dialog {
    width: 100%;
    height: 100%;
    max-width: unset !important;
}

.disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
}

.view-only > * {
    cursor: default;
    pointer-events: none;
}

/**
    MEMBERS SELECT AND INVITE DIALOG
 */
.select-multy-options-dialog {
    width: 491px;
    height: 599px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
    padding: 0 !important;
    max-width: unset !important;
}

.select-multy-options-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 24px !important;
}


/* ORGANIZATION DIALOG */
.organization-workspace-select-dialog {
    width: 464px;
    height: 468px;
    padding: 0 !important;
    max-width: unset !important;
}

.organization-workspace-select-dialog .mat-mdc-dialog-surface {
    border-radius: 20px !important;
    padding: 0 !important;
}

.unsubscribe-dialog {
    width: 795px;
    height: 468px;
    padding: 0 !important;
    max-width: unset !important;
}

@media only screen and (max-width: 768px) {
    .unsubscribe-dialog {
        width: 100%;
        height: auto;
    }
}

.unsubscribe-dialog .mat-mdc-dialog-surface {
    border-radius: 20px !important;
    padding: 0 !important;
}

.select-members-dialog-workspace {
    width: 464px;
    height: 468px;
    padding: 0 !important;
    max-width: unset !important;
}

.select-members-dialog-workspace .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 0 !important;
}

.asset-move-folder-dialog {
    width: 500px;
    height: 571px;
    padding: 0 !important;
    max-width: unset !important;
    border-radius: 15px !important;
}
.asset-move-folder-dialog .mat-mdc-dialog-surface{
    border-radius: 15px !important;
}



.organization-select-menu-content {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
    width: 370px !important;
    max-width: 370px !important;
    max-height: 150px !important;
    top: 8px;
}

.organization-select-menu-content .mat-mdc-menu-content {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.organization-select-menu-content button.mat-mdc-menu-item {
    line-height: unset !important;
    border-bottom: 1px solid rgba(123, 141, 177, 0.3);
    padding: 0 16px !important;
}

div#upload-progress-bars-container {
    position: absolute;
    bottom: 20px;
    right: 80px;
    width: 20%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}


/* story take actions dialog */

.story-take-actions-dialog {
    width: 884px;
    /*height: 544px;*/
    padding: 0 !important;
    max-width: unset !important;
}

.story-take-actions-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    padding: 0 !important;
}


/*boost audio dialog */

.boost-audio-dialog-panel {
    width: 402px;
    height: 249px;
    padding: 0 !important;
    max-width: unset !important;
}

.boost-audio-dialog-panel .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    padding: 0 !important;
}


/*eye fix dialog */
.eye-fix-dialog-panel {
    width: 424px;
    height: 245px;
    padding: 0 !important;
    max-width: unset !important;
}

.eye-fix-dialog-panel .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    padding: 0 !important;
}

/* story take style dialog */

.story-take-validation-dialog {
    width: 460px;
    height: 280px;
    padding: 0 !important;
    max-width: unset !important;
}

.story-take-validation-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    padding: 0 !important;
}

/*video preview dialog*/
.video-preview-dialog {
    width: 720px;
    aspect-ratio: 16/9;
    padding: 0 !important;
}

.video-preview-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    padding: 0 !important;
    overflow: hidden !important;
}

/*intro js */
.customTooltip * {
    color: #253658;
    font-size: 16px
}

.customTooltip .introjs-tooltip-title {
    color: #253658;
    width: 300px;
}

.audiogram-assets-dialog {
    width: 520px;
    flex-shrink: 0;
    padding: 0 !important;
    max-width: unset !important;
    overflow: hidden;
}

.audiogram-assets-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    padding: 0 !important;
    overflow: hidden;
}

/* translate captions dialog */
.translate-generate-subtitles-dialog {
    width: 840px;
    height: 472px;
    padding: 0 !important;
    max-width: unset !important;
}

.translate-generate-subtitles-dialog .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    padding: 0 !important;
}

.generate-translate-menu-container {
    max-width: 300px !important;
    width: 300px !important;
    max-height: 192px !important;
    border-radius: 10px !important;
}


.audiogram-menu-container {
    max-width: 174px !important;
    width: 174px !important;
    max-height: 192px !important;
    border-radius: 10px !important;
}

.audiogram-menu-container .mat-mdc-menu-content {
    padding: 8px !important;
}

@media screen and (max-width: 900px) {
    .audiogram-menu-container {
        width: 174px !important;
    }
}


.mdc-list-item__primary-text {
    margin-right: unset !important;
    color: unset !important;
    align-items: center !important;
    display: flex !important;
    width: 100% !important;
}

.existing-user-dialog-container {
    width: 674px !important;
    height: 498px !important;
    max-width: 674px !important;
}

.existing-user-dialog-container .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 0 !important;
    overflow: unset;
}

.app-generate-video-twin-dialog {
    width: 682px !important;
    height: auto !important;
    max-width: 100% !important;
}

.app-generate-video-twin-dialog .mat-mdc-dialog-surface {
    border-radius: 20px !important;
    padding: 24px !important;
    overflow: unset;
}


.app-subtitles-fix-dialog {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
}

.app-subtitles-fix-dialog .mat-mdc-dialog-surface {
    border-radius: 0px !important;
    padding: 0 !important;
    overflow: unset;
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    font: unset !important;
    letter-spacing: unset !important;
    text-decoration: unset !important;
    text-transform: unset !important;
    border: unset !important;
}

.mat-mdc-form-field, .mat-mdc-floating-label {
    -moz-osx-font-smoothing: unset !important;
    -webkit-font-smoothing: unset !important;
    font-family: unset !important;
    font-size: unset !important;
    line-height: unset !important;
    font-weight: unset !important;
    letter-spacing: unset !important;
    -webkit-text-decoration: unset !important;
    text-decoration: unset !important;
    text-transform: unset !important;
}


.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
    -moz-osx-font-smoothing: unset !important;
    -webkit-font-smoothing: unset !important;
    font-family: unset !important;
    font-size: unset !important;
    line-height: unset !important;
    font-weight: unset !important;
    letter-spacing: unset !important;
    -webkit-text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
    text-decoration: unset !important;
    text-transform: unset !important;
}

.mat-pseudo-checkbox {
    display: none !important;
}

/* date picker fix */
.mat-datepicker-content {
    background: white;
    border: 1px solid gainsboro;
}

.generic-input-dialog-content {
    width: 493px !important;
    padding: 20px !important;
}

.generic-input-dialog-content .mat-mdc-dialog-surface {
    border-radius: 15px !important;
    padding: 20px !important;
    overflow: unset;
}

.teleprompter-take-dialog-content {
    width: 559px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.18);
}

.teleprompter-take-dialog-content .mat-mdc-dialog-container {
    border-radius: 10px;
    position: relative;
    overflow-x: hidden;
}

.teleprompter-take-dialog-content .mat-mdc-dialog-surface {
    padding: 20px !important;
}

.teleprompter-take-dialog-content .dialog-title {
    font-size: 26px;
    margin-top: -20px;
}

video::-internal-media-controls-overlay-cast-button {
    display: none;
}

.assets-folders-menu-content button.mat-mdc-menu-item {
    padding: 0 12px !important;
}

.assets-folders-menu-content button.mat-mdc-menu-item:not(:last-child) {
    /* border-bottom: 1px solid rgba(123, 141, 177, 0.3); */
    display: table;

}
.assets-folders-menu-content button.mat-mdc-menu-item:not(:last-child):after {
    border-bottom: 1px solid rgba(123, 141, 177, 0.3);
    content: '';
    display: block;
    width: 100%;
}

.assets-dialog {
    /* width: 682px !important; */
    height: auto !important;
    max-width: 100% !important;
}


.assets-dialog .mat-mdc-dialog-surface {
    border-radius: 20px !important;
    /* padding: 24px !important; */
    overflow: unset;
}

@media only screen and (max-width: 767px) {
    .assets-dialog{
        min-width: 100vw !important;
    }
}
