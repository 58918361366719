.billing-information-container {
    width: 100%;
    margin: 0 !important;
}

.invoice-info.only-view {
    margin: 10px 0px;
    text-align: start;
    display: flex;
    align-items: flex-start;
}

div.help-text {
    font-size: 14px;
    font-weight: 600;
}

.profile-billing-inputs-container {
    width: calc(70% - 20px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 20px;
}

.checkbox-input-container {
    width: calc(40% - 40px);
    margin-right: 40px;
}

.text-input-container {
    width: 50%;
}

.credit-card-container {
    background-color: rgb(249, 249, 249);
    padding: 15px 25px;
    border-radius: 7px;
    min-height: 85px;
    width: 29%;
}

.credit-card-information {
    margin-bottom: 5px;
    font-size: 15px;
}

.credit-card-actions {
    font-weight: 600;
}

.card-link-btn {
    color: #11a9fe;
}

.billing-action-buttons-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

button.cancel-sub {
    margin-right: 20px;
}

.payments-table-section {
    margin-bottom: 20px;
    margin-top: 40px;
}

.payments-table-section .border-wrapper-invoice > table {
    width: 100%;
    border-collapse: collapse;
}

.payments-table-section thead {
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    background: rgba(123, 141, 177, 0.08);
    width: 100%;
}


.payments-table-section tr {
    background: none !important;
    border: unset !important;
}

.payments-table-section td {
    padding: 15px;
}

.payments-table-section thead td {
    padding: 19px 8px;
    font-weight: 600;
}

td.paid {
    color: #00cb86;
}

td.upcoming {
    color: #f7a876;
}

.ngdialog-cancel-subscription .ngdialog-content {
    padding: 19px!important;
    font-size: 16px!important;
    text-align: center;
}

.cancel-subscription-dialog button {
    font-size: 13px;
}

.subscription-button-container {
    width: 81px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #506aff;
    color: #fff;
    font-family: 'Inter-SemiBold';
    font-size: 12px;
}


.subscription-button-container button {
    width: 100%;
    height: 100%;
    padding: 0;
}

.billing-subtitle {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
}

.billing-plan-update-btn {
    display: flex;
    padding: 5px 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 31px;
    border: 1px solid #4481FD;
    color: #4481FD;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    text-transform: capitalize;
}

.billing-plan-cancel-btn {
    display: flex;
    padding: 5px 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 31px;
    border: 1px solid #FF4D5B;
    color: #FF4D5B;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    text-transform: capitalize;
}


@media only screen and (max-width: 1023px) {
    .credit-card-container {
        padding: 15px;
        width: 50%;
    }

    .invoice-info.view-only {
        margin: 20px 0;
    }

    .payments-table-section > table {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    div.help-text {
        font-size: 12px;
    }
    
    .invoice-info.view-only {
        flex-direction: column;
        width: 100%;
    }
    
    .profile-billing-inputs-container {
        width: 100%;
        margin: 0 0 15px 0;
    }
    
    .credit-card-container {
        width: 100%;
        padding: 10px;
    }
    
    .checkbox-input-container {
        width: 50%;
        margin-right: 0;
    }

    .billing-action-buttons-container {
        justify-content: center;
    }
    
    .billing-action-buttons-container > button {
        text-align: start;
        font-size: 10px;
    }

    .linked-card-information {
        font-size: 12px;
    }
    
    label.bigvu-input-label {
        font-size: 10px;
    }
    
    .card-link-btn {
        font-size: 12px;
    }
    
    .text-input-container > div > input {
        font-size: 12px;
    }

    .credit-card-information {
        font-size: 12px;
    }
    
    .payments-table-section thead tr {
        font-size: 11px;
    }
    
    .payments-table-section td {
        font-size: 10px;
        padding: 10px;
    }


    .cancel-subscription-dialog .modal-body {
        font-size: 16px;
        padding: 0;
    }

    .cancel-subscription-dialog .modal-footer {
        padding: 0;
        display: flex;
        margin-top: 20px;
    }

    .cancel-subscription-dialog .modal-footer button {
        width: 50%;
        margin: 0px 10px;
    }

    .ngdialog-cancel-subscription .ngdialog-content {
        padding: 25px!important;
    }

    .cancel-subscription-dialog button {
        font-size: 11px;
        padding: 0;
    }
}