@use '@angular/material' as mat;
@include mat.core();

// normalize html
@import './normalize';

//@include mat.all-component-typographies();

$bigvu-theme-primary: mat.define-palette(mat.$light-blue-palette);
$bigvu-theme-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$bigvu-theme-warn: mat.define-palette(mat.$red-palette);

$bigvu-theme: mat.define-light-theme((
        color: (
                primary: $bigvu-theme-primary,
                accent: $bigvu-theme-accent,
                warn: $bigvu-theme-warn,
        ),
));

// bigvu most common colors (pilot)
$color-bigvu-main-text: #253658;
$color-bigvu-blue: #00ABFE;
$color-bigvu-royal-blue: #4481fd;
$color-bigvu-blue-gradient: linear-gradient(270deg, #28B3F7 0.65%, #506AFF 99.35%);
$color-bigvu-blue-gray: rgba(123, 141, 177, 1);
$color-bigvu-gray-background: rgba(37, 54, 88, 0.08);

@include mat.all-component-themes($bigvu-theme);

@import url('https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/medium-editor.min.css');
@import url('https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/themes/beagle.css');
@import './global-styles';

//Remove blue outline in Chrome
div:focus {
  outline: 0 !important;
}

@font-face {
  font-family: Inter-Black;
  src: url('/fonts/Inter-Black-slnt=0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter-Bold;
  src: url('/fonts/Inter-Bold-slnt=0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url('/fonts/Inter-ExtraBold-slnt=0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url('/fonts/Inter-ExtraLight-slnt=0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter-Light;
  src: url('/fonts/Inter-Light-slnt=0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter-Medium;
  src: url('/fonts/Inter-Medium-slnt=0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter-Regular;
  src: url('/fonts/Inter-Regular-slnt=0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter-SemiBold;
  src: url('/fonts/Inter-SemiBold-slnt=0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter-Thin;
  src: url('/fonts/Inter-Thin-slnt=0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: IndieFlower;
  src: url('/fonts/IndieFlower-Regular.woff2') format('woff2');
  font-display: swap;
}

/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* PT Sans */
@font-face {
  font-family: 'PT Sans';
  src: url('/fonts/PTSans-Regular.woff2') format('woff2');
  font-display: swap;
}


:root {
  --dark-icon-filter: brightness(0) saturate(100%) invert(19%) sepia(63%) saturate(412%) hue-rotate(179deg) brightness(97%) contrast(98%);

  --white-icon-filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(7489%) hue-rotate(184deg) brightness(113%) contrast(100%);
}

* {
  font-family: Inter-Regular, sans-serif;
}

.mat-mdc-menu-panel {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
}

body {
  margin: 0;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 13px !important;
  line-height: 1.42857143 !important;
  font-weight: 400;
  color: #333333 !important;
  background-color: #fff !important;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

ul {
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

:focus {
  outline: none !important;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  opacity: unset;
}

a,
a:focus,
a:hover,
a:active,
a:visited {
  text-decoration: none !important;
}

.extra-bold {
  font-family: Inter-ExtraBold, sans-serif !important;
}

.bold {
  font-family: Inter-Bold, sans-serif !important;
}

.semi-bold {
  font-family: Inter-SemiBold, sans-serif !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: flex !important;
  align-items: center !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: unset !important;
  white-space: unset !important;
  font-family: unset !important;
  line-height: unset !important;
  font-size: unset !important;
  letter-spacing: unset !important;
  font-weight: unset !important;
}

/* bootstrap leftovers start */

.badge {
  display: inline-block;
  padding: .35em .65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .375rem;
}

/* bootstrap leftovers end */

// localizations specific styles
@import './he';







