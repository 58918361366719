.ngdialog.ngdialog-theme-default.ng-dialog-preview-video .ngdialog-content {
    height: 450px;
    width: 800px;
}
.ngdialog.ngdialog-theme-default.ng-dialog-preview-video{
    display: flex;
    align-items: center;
}
.ngdialog.ngdialog-theme-default.ng-dialog-preview-video .ngdialog-content .video-js {
    width: 100%;
    height: 450px;
}

.ng-dialog-preview-audio .vjs-audio {
    height: 30px;
    width: 100%;
}

.ng-dialog-preview-audio .vjs-audio .vjs-loading-spinner {
    top: -34px;
    left: 0;
    width: 25px;
    height: 25px;
}

.ng-dialog-preview-image img {
    max-width: 100%;
    object-fit: cover;
}

.ng-dialog-preview-audio h3 {
    margin-bottom: 23px;
    font-size: 19px;
}

button.ngdialog-close {
    border: none;
    background-color: transparent;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
    padding: 0px;
    background-color: white;
}

.asset-dialog .edit-asset-header {
    text-align: right;
    height: 65px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #f4f4f4;
    padding: 19px 15px;
    margin-bottom: 40px;
}

.asset-dialog .edit-asset-header button {
    height: 28px;
    border-radius: 4px;
}

.asset-dialog button.button-delete {
    background-color: #fef1f1;
    border: solid 1px #f77676;
    opacity: 0.9;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    color: #f77676;
    width: 95px;
}

.asset-dialog button.button-close {
    background-color: #ffffff;
    border: solid 1px #b7b7b7;
    position: relative;
    top: -1px;
    width: 28px;
}

.asset-dialog button.button-save {
    background-color: #e7f6fe;
    border: solid 1px #11a9fe;
    opacity: 0.9;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    color: #11a9fe;
    margin: 0 7px;
    width: 93px;
}

.asset-dialog button.button-delete img {
    position: relative;
    top: -2px;
    left: -4px;
}

.asset-dialog button.button-save img {
    position: relative;
    left: -8px;
}

.asset-dialog button.button-close img {
    width: 12px;
    height: 12px;
}

.asset-dialog .edit-assets-container-left, .asset-dialog .edit-assets-container-right {
    text-align: left;
}

.asset-dialog .edit-assets-container-left span.title, .asset-dialog .edit-assets-container-right span.title {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: bold;
    color: #bebebe;
}

.asset-dialog .edit-assets-container-right textarea {
    resize: none;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #c6c6c6;
    display: block;
    width: 100%;
    height: 90px;
    margin: 5px 0 14px;
    padding: 8px;
}

.asset-dialog .dialog-asset-duration {
    width: 42px;
    height: 20px;
    border-radius: 3px;
    background-color: rgba(119, 146, 178, 0.9);
    font-family: HelveticaNeue, Helvetica;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    float: right;
    margin-top: 7px;
}

.ng-dialog-add-tweet-url .ngdialog-content {
    display: table;
}

.ngdialog.ngdialog-theme-default {
    padding-top: 100px;
    padding-bottom: 100px;
}

.ngdialog.ngdialog-theme-default.ng-dialog-preview-video .ngdialog-content .video-js button.vjs-big-play-button {
    border-radius: 100% !important;
    width: 1.9em !important;
    height: 1.9em !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
    top: 40% !important;
}

.ngdialog.ngdialog-theme-default.ng-dialog-preview-video .ngdialog-content .video-js button.vjs-big-play-button::before {
    top: 4px !important;
    left: 1px;
}

.ngdialog-subscribe-to-continue .modal-body {
    padding: 26px;
    text-align: center;
    font-size: 19px;
    line-height: 26px;
}

.ngdialog-cancel-subscription .modal-footer {
    text-align: center;
}

.ngdialog.ngdialog-theme-default.custom-width-50 .ngdialog-content {
    width: 50%;
}

audio#preview-dialog-audio::-webkit-media-controls-enclosure {
    overflow: hidden;
}

audio#preview-dialog-audio::-webkit-media-controls-panel {
    width: calc(100% + 30px);
}

audio#preview-dialog-audio {
    width: 100%;
}

.preview-audio-dialog {
    padding: 1px 10px 10px;
}

.ngdialog-change-email {
    padding: 15px;
    display: inline-block;
    width: 100%;
}

.ngdialog-change-email span {
    font-size: 17px;
    margin-bottom: 10px;
    display: block;
}

.ngdialog-change-email .ngdialog-buttons {
    margin-top: 20px;
}

.ngdialog-captions-lang.modal-body .langs-container > div {
    padding: 10px;
}

.ngdialog-captions-lang.modal-body .langs-container > div:not(:last-of-type) {
    border-bottom: 1px solid #e7e7e7;
}

.ngdialog-captions-lang.modal-body .langs-container {
    margin-top: 14px;
}

.ngdialog-captions-lang.modal-body > span:first-of-type {
    text-transform: uppercase;
}


video-popup-dialog .video-popup-description-text ul{
    list-style: inside;
}
video-popups-edit-dialog .medium-editor-element ul {
    list-style: inside
}

.change-email-dialog-container {
    width: 464px;
    min-width: 464px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.change-email-dialog-container .mat-mdc-dialog-surface {
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.change-email-dialog-container .mat-mdc-dialog-container .dialog-close-button-container {
    position: absolute;
    top: 8px;
    right: 8px;
}

.congrats-payment-dialog-container {
    width: 464px;
    height: 351px;
}

.congrats-payment-dialog-container  .mat-mdc-dialog-surface {
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.payment-dialog-container {
    width: 464px;
}

.payment-dialog-container  .mat-mdc-dialog-surface {
    padding: 14px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.social-dialog-container {
    width: 274px;
    height: auto;
}

.social-dialog-container-extras {
    width: 274px;
    height: auto;
}

.social-dialog-container-extras .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 16px !important;
}

.change-email-dialog-container text-input {
    width: 100%;
}


.pages-list-edit-dialog-container {
    width: 464px;
    min-width: 464px;
    height: 245px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.pages-list-edit-dialog-container .mat-mdc-dialog-surface {
    padding: 10px 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.pages-list-edit-dialog-container .mat-mdc-dialog-container .dialog-close-button-container {
    position: absolute;
    top: 8px;
    right: 8px;
}

.pages-list-share-dialog-container {
    width: 640px;
    min-width: 640px;
    height: 276px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.pages-list-share-dialog-container .mat-mdc-dialog-surface {
    padding: 10px 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.pages-list-share-dialog-container .mat-mdc-dialog-container .dialog-close-button-container {
    position: absolute;
    top: 8px;
    right: 8px;
}


.pages-list-edit-dialog-container-template {
    width: 980px;
    min-width: 980px;
    height: 372px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.facebook-group-pages-dialog {
    width: 570px;
    height: 399px;
    min-width: 570px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.facebook-group-pages-dialog .mat-mdc-dialog-surface {
    padding: 10px 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}


.social-dialog-container .mat-mdc-dialog-surface {
    border-radius: 10px !important;
    padding: 16px !important;
}

.pages-list-edit-dialog-container-template .mat-mdc-dialog-surface {
    padding: 10px 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.pages-list-edit-dialog-container-template .mat-mdc-dialog-container .dialog-close-button-container {
    position: absolute;
    top: 8px;
    right: 8px;
}

.text-field-container {
    margin: 15px 0;
}

.organization-details-dialog-content {
    width: 40px;
    min-height: 250px !important;
    max-width: unset !important;
}

.organization-details-dialog-content .mat-mdc-dialog-surface {
    padding: 10px 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.dialog-button-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-button-container button {
    width: 70px;
    border-radius: 10px;
    padding: 5px;
}

.dialog-button-container button.enabled {
    background-color: #00abfe;
    color: #fff;
}

.dialog-button-container button.disabled {
    background: grey;
    color: lightgrey;
    pointer-events: none;
}

.menu-container {
    width: 50%;
    margin-bottom: 18px;
}

::ng-deep .menu-container select-menu .select-menu-container {
    border-radius: 10px;
    background-color: rgba(123, 141, 177, 0.08);
    height: 46px;
}

::ng-deep .menu-container select-menu .select-menu-container .trigger-button-text {
    font-size: 14px;
    color: #7b8db1;
    position: relative;
    margin-top: 5px;
}

.dialog-action-buttons {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.dialog-action-button-container {
    width: 196px;
    height: 44px;
    border-radius: 10px;
}

.dialog-action-button-container button {
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 16px;
    font-family: Inter-Bold;
    color: inherit;
}

.dialog-action-button-container.cancel-button {
    background: rgba(123, 141, 177, 0.08);
    color: #7b8db1;
}

.dialog-action-button-container.submit-button {
    background: #00abfe;
    color: #fff;
}

.dialog-action-button-container.submit-button.disabled {
    opacity: .5;
    pointer-events: none;
    cursor: default;
}