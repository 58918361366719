image-edit-dialog .close-container  {
    top: -15px;
    right: -22px;
}

.image-edit-dialog-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 9px;
}


.image-edit-dialog-header{
    color: #253658;
    font-size: 18px;
    font-family: 'Inter-Bold';
    font-weight: 800;
    text-align: center;
    margin: 0 0 10px 0;

}
.image-edit-dialog-btn-cancel{
    width: 140px;
    height: 36px;
    margin: 0 16px 0 0;
    border-radius: 10px;
    text-align: center;
    font-weight: 600;
    background-color: rgba(123, 141, 177,0.08);
    color: #253658;
    font-family: 'Inter-Bold';

}
.image-edit-dialog-btn-submit{
    width: 140px;
    height: 36px;
    margin: 0 !important;
    border-radius: 10px;
    text-align: center;
    font-family: 'Inter-Bold';
}
.image-edit-dialog-btn-cancel:hover .image-edit-dialog-btn-submit:hover{
    opacity: 0.8
}
.image-edit-dialog-btn-enabled{
    background-color: #00abfe;
    color: white;
}
.image-edit-dialog-btn-disabled{
    background-color: #cccccc;
    color: #666666;
    cursor: default;
    pointer-events: none;
}

.image-edit-dialog-inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 25px 0 25px;
    position: relative;
    overflow: hidden;
}

.image-edit-dialog-close-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    z-index:2;
    opacity: 0.8;
}
.image-edit-dialog-close-icon:hover{
    opacity: 1;
}
