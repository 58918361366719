.video-popup-edit-dialog {
    width: 410px;
    min-height: 250px !important;
    max-width: unset !important;
}

.video-popup-edit-dialog .mat-mdc-dialog-surface  {
    border-radius: 10px !important;
    padding: 0 !important;
    overflow: unset;
}

.video-popup-edit-dialog .asset-dialog-header {
    font-size: 20px;
    font-weight: bold;
    color: #000;
}

.video-popup-edit-dialog .header-separator {
    margin: 11px -24px 0px;
}

.video-popup-edit-dialog .asset-dialog-close:before,
.video-popup-edit-dialog .asset-dialog-close:after {
    height: 16px;
    right: 0;
    left: unset !important;
}
