/* NEW DESIGN */


.close-button-sidebar {
    position: absolute;
    right: 12px;
    top: 32px;
}

.sidebar-container {
    width: 500px;
    background-color: white;
    height: calc(100vh - 65px);
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.18);
    overflow: hidden;
}

.shimmer-container-pixabay{
    width: 95%;
    align-self: center;
    justify-self: center;
}

.sidebar-header {
    font-size: 26px;
    font-weight: bold;
    border-bottom: 1px solid #efefef;
    padding: 18px 20px 10.6px;
    width: 500px;
}

.sidebar-search-container {
    display: flex;
    flex-direction: row;
    height: 80px;
    margin-bottom: 29px;
    width: 100%;
    justify-content: center;
}

.search-assets,
.search-tweets {
    margin-bottom: 0;
}

.sidebar-search-container select-menu {
    color: white;
    position: relative;
    width: 112px;
    height: 40px;
    margin: 32px 0px 0;
    background-color: #00abfe;
    border-radius: 5px;
    font-size: 14px;
    font-weight: normal;
}

.sidebar-search-container select-menu .triggers {
    padding: 10px 7px 10px 0 !important;
}

.pixabay-search-form {
    display: flex;
    flex-direction: row;
    padding: 10px 0 0 0px;
    position: relative;
    background-color: #f2f4f5;
    margin: 32px 23px 0 0;
    width: 321px;
    height: 40px;
    border-radius: 5px;
}

.search-button {
    width: 40px;
    position: absolute;
    top: 10px;
    padding-left: 15px;
    font-size: 17px;
    color: #a2a6ab;
    cursor: pointer;
}

input.pixabay-search {
    width: 314px;
    margin-left: 33px;
    background-color: #f2f4f5;
    height: 22px;
    color: #000;
    font-size: 12px;
    border: none;
}

.aspect-ratio-menu {
    padding-top: 8px;
    right: 0px;
    position: absolute;
}

.aspect-ratio-menu-composerv2 {
    padding-top: 8px;
    right: 20px;
    position: absolute;
}

.selected-ratio {
    background-color: rgba(0, 0, 0, .04);
}

.filter-menu-content-style {
    background-color: white !important;
    color: black !important;
    padding: 0 7px !important;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    max-width: 200px !important;
    max-height: 400px !important;
}

button.mat-mdc-menu-item:hover {
    background: rgba(0, 0, 0, .04);
}

.chevron::before {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: '';
    display: inline-block;
    height: 6px;
    left: 0;
    position: relative;
    vertical-align: top;
    width: 6px;
    cursor: pointer;
}

.chevron.closed:before {
    top: 6px;
    transform: rotate(135deg);
}

.chevron.opened:before {
    top: 10px;
    transform: rotate(-45deg);
}

.info-box-container {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 0 12px 7px;
    font-size: 11px;
    height: 30px;
    justify-content: center;
    align-items: center;
}


.sidebar-container upload-media-box {
    width: 45%;
    height: 170px;
    overflow: hidden;
    border-radius: 7px;
    position: relative;
}

.sidebar-container upload-media-box .upload-preview-image{
    height: 130px;
}



media-box.media-box-item {
    width: 45%;
    height: 170px;
    margin: 5px;
    border-radius: 7px;
}

media-box.media-box-item:nth-child(1),
media-box.media-box-item:nth-child(2) {
    margin: 0 5px 5px;
}

.media-box,
.asset-box {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
}

.content-wrapper {
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
}

.media-box .content-wrapper .add-button-container {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 47px;
    height: 30px;
    background-color: #00d096;
    border-radius: 10px;
}

.content-wrapper:hover .add-button-container {
    display: block!important;
}

.media-box .content-wrapper .add-button-container:hover {
    opacity: .8;
}

.media-box .content-wrapper .add-button-container button {
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 10px;
    color: #fff;
    font-family: Inter-SemiBold;
    text-align: center;
}

img.media-content-img,
video.media-content-video {
    height: 130px;
    object-fit: cover;
    width: 100%;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: rgba(123, 141, 177, 0.08);
}

.info-box-title {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.19px;
    text-align: start;
}

.info-box-controls-container {
    width: 97px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 10px;
    font-size: 10px;
}

asset-media-box.asset-sidebar-media-box-item {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    align-self:center;
    justify-items: center;
}


.info-box-item {
    margin-left: 12px;
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.clickable {
    cursor: pointer;
    pointer-events: all;
    padding-top: 4px;
}

.left-bars a.unclickable {
    display: inline-flex;
    width: 100%;
}

.fa-thumbs-up:before {
    color: #fff;
    text-shadow: 0px 0px 1px #000;
    font-size: 11px;
}

.fa-star:before {
    color: #fff;
    text-shadow: 0px 0px 1px #000;
    font-size: 11px;
    margin-right: 1px;
}

.fa-trash:before {
    color: #fff;
    text-shadow: 0px 0px 1px #000;
    font-size: 11px;
}

.fa-info-circle::before {
    color: #fff;
    text-shadow: 0px 0px 1px #000;
    font-size: 11px;
    margin-right: 1px;
}

.fa-retweet::before {
    font-size: 11px;
}

.fa-heart::before {
    color: #fff;
    text-shadow: 0px 0px 1px #000;
    margin-left: 1px;
}

.asset-upload-button {
    width: 456px;
    height: 40px;
    border-radius: 5px;
    border: 1px dashed #00abfe;
    background-color: #f0faff;
    margin: 10px auto 0;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    position: relative;
}

.asset-upload-button button {
    background: none;
    color: #00abfe;
    font-weight: 600;
    width: 100%;
    height: 40px;
    margin: -10px;
}

.asset-upload-button button input {
    display: none;
}

.menu-header.mat-mdc-menu-item {
    cursor: text;
}

.menu-header.mat-mdc-menu-item:hover {
    background: none;
}

.three-dots-vertical {
    background-image: url('/img/3-dots.svg');
    width: 34px;
    height: 34px;
    object-fit: contain;
    background-repeat: no-repeat;
    padding: 0;
    display: block;
}

.three-dots-vertical-black {
    background-image: url('/img/three-dot-black.svg');
    width: 34px;
    height: 34px;
    object-fit: contain;
    background-repeat: no-repeat;
    padding: 0;
    display: block;
}


.three-dots-vertical-white-circle {
    background-image: url('/img/3-w.svg');
    width: 24px;
    height: 24px;
    object-fit: contain;
    background-repeat: no-repeat;
    padding: 0;
    display: block;
}

.three-dots-vertical-white-circle-active {
    background-image: url('/img/3-w-selected.svg');
    width: 24px;
    height: 24px;
    object-fit: contain;
    background-repeat: no-repeat;
    padding: 0;
    display: block;
}

.three-dots-horizontal {
    background-image: url('/img/3-dots.svg');
    width: 34px;
    height: 34px;
    object-fit: contain;
    background-repeat: no-repeat;
    padding: 0;
    transform: rotate(90deg);
    display: block;
}

.folders-menu-container {
    color: black;
    position: relative;
    height: 40px;
    margin: 12px 0px 20px 20px;
    background-color: white;
    padding: 0px 0px;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
}

.folder-filter-container {
    width: 165px;
    margin-top: 20px;
    position: relative;
    cursor: pointer;
}

.folder-filter-container>span {
    position: absolute;
    left: 92px;
}

.folder-filter-container>button {
    position: relative;
    width: 112px;
    padding-left: 9px;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

i.fa.fa-folder {
    color: #00abfe;
    position: absolute;
    left: 10px;
    top: 5px;
    width: 30px;
}

.media-menu-item {
    width: 100%;
}

.folder-menu-item {
    width: 161px !important;
}

.add-new-folder {
    color: #0075f8;
    font-weight: 500;
    font-size: 14px;
}

.checkbox-filters-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px 14px 15px;
    width: 350px;
}

.checkbox-filters-container label {
    font-weight: normal;
    font-size: 14px;
    margin: 0 20px 0 0;
    user-select: none;
    cursor: pointer;
}

.checkbox-filters-container input {
    margin: 0 8px 0 0;
}

.twitter-results-wrapper {
    position: relative;
    height: 100%;
    overflow: scroll;
}

.tweet-box {
    position: relative;
}

tweet-box.tweet-box-item {
    width: 100%;
    display: inline-block;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.11);
    margin: 5px 5px;
    color: #6d7278;
}

.tweet-container {
    padding: 16px 13px 0;
}

.tweet-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-img img {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background-color: black;
}

.tweet-username {
    font-weight: 600;
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 15px;
    color: #000000;
}

.tweet-screenname {
    width: 100px;
    font-size: 13px;
    font-weight: normal;
    color: #a2a6ab;
}

.tweet-name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
}

.tweet-text {
    margin-top: 15px;
}

.twitter-info-box-controls-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -10px;
    width: 100%;
}

.droppable-container {
    height: 100%;
    margin-top: -10px;
    width: 100%;
    text-align: center;
}

.dragged-tweet {
    width: 224px !important;
    background-color: #fff;
    z-index: 1000;
}

.media-content-font {
    width: 100%;
    height: 130px;
    padding: 12px;
    color: #000;
}

/* NEW DESIGN - END */

#twitter-bar,
#pixabay,
#assets,
#assets-uploads,
#assets-style,
#style,
#video-assets,
#image-assets,
#video-assets-uploads,
#image-assets-uploads {
    position: absolute;
    background: transparent;
    padding: 0;
    width: 500px !important;
    /* box-shadow: 0 5px 10px rgba(134, 134, 134, 0.38); */
    height: calc(100vh - 132px) !important;
    right: -500px;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    z-index: 26;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 92px;
}

#ai-generate-image{
    position: absolute;
    background: transparent;
    padding: 0;
    width: 500px !important;
    /* box-shadow: 0 5px 10px rgba(134, 134, 134, 0.38); */
    max-height: 476px;
    min-height: 476px;
    right: -500px;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    z-index: 26;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 200px !important;
}

#assets-style {
    overflow-y: scroll;
}

.edit-assets-container-right input {
    height: 30px;
    font-family: Open Sans;
    font-size: 13px;
    color: #404040;
    width: 100%;
    border: 0;
    margin-bottom: 11px;
    border-bottom: 1px solid #dedede;
}

.dialog-keywords {
    margin: 10px 0;
    width: 100%;
    height: 90px;
    background-color: #ffffff;
    border-radius: 4px;
    border: solid 1px #c6c6c6;
}

.dialog-keywords .tags {
    height: 88px !important;
    overflow: auto;
    border: none !important;
    box-shadow: none !important;
}

.dialog-keywords .tags .tag-item {
    border-radius: 3px;
    background: #ffffff !important;
    border: solid 1px #d0d0d0;
}

.dialog-keywords span {
    height: 16px;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    color: #808080;
}

.dialog-asset-info {
    margin: 12px 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #aaaaaa;
}

.dialog-asset-info-font-url {
    margin: 12px 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #aaaaaa;
    word-break: break-all;
}

.dialog-asset-left {
    clear: both;
}

.dialog-asset-left textarea {
    clear: both;
    float: left;
    margin-top: 10px;
    width: 200px;
    height: 103px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #aaaaaa;
}

.asset-ratio {
    color: #404040 !important;
    font-family: Open Sans;
    font-size: 13px;
    text-transform: capitalize;
    margin-left: 10px;
}

.sb-asset-type {
    display: inline;
    background-color: #ffffff;
    border: solid 1px #88a4fb;
    color: #88a4fb;
    padding: 2px;
    font-family: 'PT Sans', sans-serif;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #88a4fb;
    padding: 0 5px;
}

.asset-delete-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #606060;
    text-align: center;
}

.assetsBar-tags {
    width: 454px;
}

.assetsBar-tags .tags {
    border: none !important;
    box-shadow: none !important;
    width: 454px;
    /* height: 32px; */
    overflow-y: hidden;
    -webkit-appearance: none;
}

.assetsBar-tags .tags .tag-item {
    border-radius: 3px;
    background: #ffffff !important;
    border: solid 1px #d0d0d0;
}

.more-img {
    display: inline;
    margin-left: 10px;
}

.assets-name {
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 28px;
    letter-spacing: normal;
    color: #ffffff;
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
}

.upload-side-div {
    cursor: pointer;
    margin-left: 86px;
}

#file-drop-side {
    height: 100%;
    margin-top: -6px;
}

.newsideAsset {
    display: none !important;
}

#assetVideo video {
    background-size: cover;
    height: 173px;
    position: absolute;
    top: 1px;
    bottom: 0;
    left: 0px;
    right: 0px;
    border-radius: 3px;
}

.assets-search.search-sidebar {
    padding: 10px !important;
}

.assets-side-upload {
    height: 54px;
    padding: 0 13px;
    border-radius: 3px;
    background-color: rgba(0, 203, 134, 0.05);
    clear: both;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 51px;
    font-size: 16px;
    color: #606060;
}

.assetsBar-header {
    height: 50px;
    padding-left: 9px;
}

.assetBar-desks {
    height: 54px;
    line-height: 54px;
}

.assets-board-selection-dropdown {
    background-color: #ffffff;
    box-shadow: none !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #909090;
    margin-left: 0 !important;
    padding: 6px;
}

.side-assets-query {
    font-size: 15px;
    width: 35%;
    border: 0px;
    margin-top: 12px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c0c0c0;
}

.assets-side-tabs {
    margin: 10px 9px;
    line-height: 30px;
}

.sb-asset-type.selected {
    background-color: #88a4fb !important;
    color: #ffffff !important;
}

.assets-info,
.pixabay-info {
    color: #ffffff;
    height: 30px;
    line-height: 30px;
    width: 100%;
    padding: 0 10px;
    bottom: 0px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    clear: both;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-family: Open Sans !important;
    font-size: 11px;
    color: #ffffff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.assets-info .fa,
.pixabay-info .fa {
    color: #ffffff;
    display: inline-block;
    line-height: 30px;
}

.assets-side-square {
    background: url(/img/mediaAssets/square.svg) no-repeat;
    float: left;
    margin-right: 14px;
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.assets-side-landscape {
    width: 25px;
    height: 12px;
    border-radius: 1px;
    margin-right: 10px;
    float: left;
    background: url(/img/mediaAssets/landscape.svg) no-repeat;
}

.assets-side-vertical {
    width: 12px;
    height: 25px;
    float: left;
    margin-left: 7px;
    margin-right: 16px;
    background: url(/img/mediaAssets/vertical.svg) no-repeat !important;
}

.side-vertical-selected {
    width: 25px;
    height: 25px;
    margin: -1px -1px 0px;
    padding: 0px !important;
    box-shadow: none !important;
    background: url(/img/mediaAssets/circle-vertical.svg) no-repeat !important;
}

.side-square-selected {
    width: 25px;
    height: 25px;
    box-shadow: none !important;
    background: url(/img/mediaAssets/circle-square.svg) no-repeat !important;
    padding: 0px !important;
    margin: -1px -1px 0px;
}

.assets-side-drop {
    float: left;
    position: relative;
    margin-left: 10px;
    width: 25px;
    height: 25px;
    border-radius: 20px;
    background-color: #f4f4f4;
    border: solid 1px #c0c0c0;
    margin-top: 14px;
}

.side-landscape-selected {
    height: 25px;
    width: 25px;
    padding: 0px !important;
    box-shadow: none !important;
    margin: -1px -1px 0px;
    background: url(/img/mediaAssets/circle-horizontal.svg) no-repeat !important;
}

.assets-side-all {
    background: url(/img/mediaAssets/all.svg) no-repeat !important;
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 10px;
}

.assets-side-all-selected {
    background: url(/img/mediaAssets/circle-all.svg) no-repeat !important;
    box-shadow: none !important;
    width: 25px;
    height: 25px;
    margin-left: -1px;
    margin-top: -1px;
}

.assets-dropdown-sidebar {
    font-family: 'OpenSans', sans-serif !important;
    font-weight: 600 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal !important;
    font-size: 14px;
    letter-spacing: normal;
    color: #c7c7c7 !important;
}

.assets-dropdown-sidebar.selected {
    color: #909090 !important;
}

.pending-image {
    pointer-events: none;
}

.pending-item {
    height: 100%;
    position: relative;
    text-align: center;
}

.pending-item div {
    position: absolute;
    background-color: #9F1846;
    bottom: 0;
    height: 32px;
    width: 100%;
}

.pending-item span {
    color: white;
    font-weight: 400;
    line-height: 32px;
    vertical-align: middle;
    font-size: 13px;
}

#sidebar {
    position: fixed;
    background: #fff;
    box-shadow: 0 5px 10px rgba(51, 51, 51, 0.38);
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
    z-index: 26;
    width: 184px;
    overflow: hidden;
    filter: alpha(opacity=0);
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    height: calc(100% - 65px);
    top: 67px;
}

#sidebar .sidebar-inner {
    height: 100%;
    overflow-y: auto;
    width: 100%;
}

#sidebar .sidebar-inner .si-inner {
    width: 100%;
    height: 100%;
    margin-top: -2px;
    position: relative;
    display: flex;
    flex-direction: column;
}

#sidebar.toggled,
#twitter-bar.toggled {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
}

#sidebar .profile-menu>a {
    display: flex;
    flex-direction: column;
    height: 120px;
    width: 100%;
    padding: 9px 17px;
}

@media only screen and (min-width: 1024px) {
    #sidebar .main-menu>li:last-child {
        /* position: absolute; */
        bottom: 12px;
        width: 100%;
    }
}

#sidebar .main-menu>li>a>i {
    position: absolute;
    left: 16px;
    font-size: 20px;
    text-align: center;
    padding: 9px 0;
}

#sidebar .main-menu>li.sub-menu>a:before,
#sidebar .main-menu>li.sub-menu>a:after {
    position: absolute;
    right: 10px;
    top: 12px;
    color: #575757;
    font-family: 'Material Design Iconic Font';
    font-size: 17px;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

#sidebar .main-menu>li.sub-menu>a:before {
    content: "\f0fb";
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

#sidebar .main-menu>li.sub-menu>a:after {
    content: "\f111";
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}

#sidebar .main-menu>li.sub-menu>ul {
    list-style: none;
    background: #f7f7f7;
    display: none;
    padding: 0;
}

#sidebar .main-menu>li.sub-menu>ul>li>a {
    color: #7f7f7f;
    padding: 8px 20px 8px 50px;
    font-weight: 500;
    display: block;
}

#sidebar .main-menu>li.sub-menu>ul>li>a.active,
#sidebar .main-menu>li.sub-menu>ul>li>a:hover {
    color: #000;
}

#sidebar .main-menu>li.sub-menu>ul>li:first-child>a {
    padding-top: 14px;
}

#sidebar .main-menu>li.sub-menu>ul>li:last-child>a {
    padding-bottom: 16px;
}

#sidebar .main-menu>li.sub-menu.toggled>a:before {
    content: "\f0fb";
    -webkit-transform: scale(0) rotate(-90deg);
    -ms-transform: scale(0) rotate(-90deg);
    -o-transform: scale(0) rotate(-90deg);
    transform: scale(0) rotate(-90deg);
}

#sidebar .main-menu>li.sub-menu.toggled>a:after {
    content: "\f111";
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

#sidebar .main-menu>li.sub-menu.active>ul {
    display: block;
}

#sidebar .main-menu>li.active>a,
#sidebar .main-menu>li>a:hover {
    background-color: #e5f1fe;
    color: #0075f8;
}


/*
 * Full width layout
 */

body:not(.sw-toggled) #sidebar {
    box-shadow: 0 3px 10px rgba(134, 134, 134, 0.38);
}

@media (min-width: 1200px) {
    body.sw-toggled #sidebar {
        -webkit-transform: translate3d(0, 0, 0) !important;
        transform: translate3d(0, 0, 0) !important;
        opacity: 1 !important;
        filter: alpha(opacity=100) !important;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    }
    #sidebar .main-menu>li:nth-last-child(2) {
        flex-grow: 1;
    }
}

@media (max-width: 1200px) {
    body.sw-toggled #sidebar {
        box-shadow: 0 0 10px rgba(51, 51, 51, 0.38);
    }
}


/*
 * For Stupid IE9
 */

@media (min-width: 1200px) {
    .ie9 body.sw-toggled #sidebar {
        display: block;
    }
}

.ie9 body:not(.sw-toggled) #sidebar:not(.toggled) {
    display: none;
}

#sidebar .main-menu i {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    top: 12px !important;
}

.twitter-results-holder {
    display: block;
    position: relative;
    min-height: calc(100% - 46px);
    z-index: 999;
    width: 100%;
    margin-bottom: 46px;
    overflow: scroll;
    padding: 10px;
}

.pixabay-results-holder{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-auto-rows:  auto; 
    grid-template-rows: repeat(6, 1fr); 
    justify-content: center;
    justify-items: center;
    margin: 3px 15px;
    height: calc(100vh - 330px);
    width: 100%;
    padding: 20px;
    row-gap: 16px;
    column-gap: 10px;
    overflow: scroll;
    position: relative;   
}

.asset-results-holder{
    position: relative;   
    row-gap: 16px;
    column-gap: 10px;
    width: 100%;
    margin: unset !important;
    overflow: hidden auto !important;
    min-height: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-auto-rows: max-content;
    grid-template-rows: repeat(6, 1fr); 
    justify-content: center;
    justify-items: center;
    padding: 20px;
    max-height: 100%;
}

.twitter-results-holder {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 1em;
    -webkit-column-gap: 1em;
    column-gap: 10px;
}

tweetbox.tweetbox,
pixabaybox,
assetsbox {
    display: inline-block;
    width: 100%;
    margin-top: 5px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.mouse-dragging:not(.assets) {
    position: relative;
    background-color: none;
    height: 100%;
    overflow: scroll;
}

.tweetbox2 {
    width: 100%;
    overflow: hidden;
    background: white;
    border: 1px solid #dedede;
    border-radius: 3px !important;
    font-size: 90%;
    line-height: 18px;
    float: none;
}

.tweetbox .tweet-content {
    width: 100%;
    padding: 10px;
}

.tweetbox .tweet-userimg {
    width: 32px;
    height: 32px;
    float: left;
    padding-right: 5px;
}

.tweetbox .tweet-userimg img {
    width: 100%;
}

.tweetbox-preview .tweet-username {
    font-weight: bold;
    font-size: 14px;
    margin-left: 4px;
    line-height: 12px;
}

.tweetbox-preview .tweet-screenname {
    color: gray;
}

.tweetbox .tweet-username {
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #404040;
    margin-top: -3px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 168px;
    white-space: nowrap;
}

.tweetbox .tweet-screenname {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.67;
    color: #909090;
    margin-top: -6px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 167px;
    white-space: nowrap;
}

.tweetbox .tweet-text {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    word-wrap: break-word;
}

.tweetbox .tweet-date {
    margin: 5px 1px 5px 10px;
    color: #8899a6;
    font-size: 100%;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    font-family: Open Sans;
}

.pixabay-sidebar-top-container,
.twitter-bar-buttons-container {
    height: 54px;
}

.twitter-bar-search-input-container {
    width: 264px;
    height: 54px;
    float: left;
}

input.pull-left.twitter-search,
input.pull-left.pixabay-search {
    height: 54px;
    font-family: Open Sans;
    font-size: 15px;
    border: 0;
    width: 212px;
    color: #444444;
}

.twitter-bar-buttons-container i.md-more-vert {
    transform: rotate(90deg);
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #88a4fe;
    height: 28px;
    width: 16px;
}

.twitter-bar-buttons-container .langauge-container {
    margin: 0 15px;
}

div#twitterbar-filter-continer,
div#pixabay-filter-container {
    height: 16px;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #88a4fe;
    font-family: PT Sans;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    color: #88A4FB;
    width: 82px;
    text-transform: uppercase;
    margin: 19px 11px 19px 0;
    line-height: 15px;
}

div#twitterbar-filter-continer .twitterbar.filter,
div#pixabay-filter-container .pixabaybar.filter {
    border-right: 0;
    border-top-right-radius: 0;
    height: 14.3px;
}

div#twitterbar-filter-continer .twitterbar.filter:first-child {
    width: 32px;
}

div#twitterbar-filter-continer .twitterbar.filter:last-child {
    width: 45px;
}

label.twitterbar.filter.active,
label.pixabaybar.filter.active {
    color: white;
    background-color: #88A4FB;
}

.twitter-sidebar-actions-container.pull-right {
    height: 54px;
    line-height: 54px;
}

.twitter-bar-buttons-container i.md-more-vert:before {
    position: relative;
    left: -3px;
    top: -1px;
}

a.filter-drop-down {
    margin: 12px 12px 0 0px;
}

.tweetbox-twitter-info-container {
    padding: 10px 10px 2px;
    font-family: Open Sans;
    color: #aaaaaa;
    font-size: 11px;
    display: inline-block;
    width: 100%;
}

.pull-left.tweet-time {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 60px;
    white-space: nowrap;
}

.tweetbox-img-container {
    padding: 0 10px;
}

.tweetbox-img-container img.tweet-image {
    width: 100%;
}

.tweetbox-img-container a {
    height: 100%;
    display: block;
}

.pixabay-sidebar-top-container i,
.pull-left.search-twitter i,
.assets-bar-buttons-container i.md-search {
    font-size: 19px;
    font-weight: 100;
    color: #c2c2c2;
    margin: 18px 4px 6px 9px;
}

input.pull-left.twitter-search::placeholder,
input.pull-left.pixabay-search::placeholder {
    color: #c0c0c0;
}

a.filter-drop-down:before {
    opacity: 0 !important;
}

.side-bar-no-results-text {
    clear: both;
    margin-top: 100px;
    width: 100%;
    position: absolute;
    text-align: center;
}

.side-bar-no-results-text h1 {
    color: #253658;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
}

.side-bar-no-results-text p {
    color: #7B8DB1;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

tweetbox.tweetbox.ui-draggable-dragging {
    width: 170px;
    display: inline-block;
    cursor: move;
}

pixabaybox.tweetbox.ui-draggable-dragging {
    width: 170px;
    height: auto;
    display: block;
}

div#twitterbar-filter-continer .twitterbar.filter,
div#pixabay-filter-container .pixabaybar.filter {
    border-right: 0;
    border-top-right-radius: 0;
}

div#pixabay-filter-container .pixabaybar.filter:first-child {
    width: 43px;
}

div#pixabay-filter-container .pixabaybar.filter:last-child {
    width: 42px;
}

div#pixabay-filter-container {
    width: 90px;
}

input.pull-left.pixabay-search {
    width: 348px;
}

.tweetbox2.pixabay-box,
.tweetbox2.assets-box {
    height: 175px;
}

.info-box-controls-container>.info-box-item span {
    color: black;
    margin-right: 5px;
}

.twitter-info-box-controls-container>.info-box-item span {
    margin-left: 5px;
}

.pixabay-content {
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    background-position: center;
    height: 100%;
    width: 100%;
}


.assets-info i.pull-right.fa.fa-trash-o {
    font-size: 16px;
}

.assets-info {
    padding-right: 0;
}

.assetsBar-header.assets-bar-buttons-container>.dropdown {
    margin-top: 13px;
}

.assets-content-video {
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.assets-content {
    height: 175px;
    background-size: contain;
    background-position: 50%;
    display: block;
    background-repeat: no-repeat;
    position: relative;
}

.assets-results-conatiner {
    position: relative;
    background-color: #f4f4f4;
    height: 100%;
    border-top: 1px solid #efefef;
    overflow: scroll;
}

.mouse-dragging.assets {
    height: 100%;
}

img.edit-assets-img {
    width: 100%;
    max-height: 159px;
    object-fit: cover;
    object-position: center;
}

.edit-assets-container-left,
.edit-assets-container-right {
    text-align: left;
}

.edit-assets-container-left span.title,
.edit-assets-container-right span.title {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: bold;
    color: #bebebe;
}

.edit-assets-container-right textarea {
    resize: none;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #c6c6c6;
    display: block;
    width: 100%;
    height: 90px;
    margin: 5px 0 14px;
}

.assets-search-container {
    padding-left: 10px;
    position: relative;
}

.assets-search-container i.md-search {
    position: relative;
    top: 9px;
    margin-right: 7px;
}

assets-bar form {
    display: inline-block;
    width: 100%;
}

.assets-content.loading-asset-img {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 0px;
    width: 227px;
    background: white;
    border: 1px solid #dedede;
    border-radius: 3px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
}

.assets-content.loading-asset-img .assets-info {
    bottom: 0px;
    top: auto;
    position: absolute;
}

#sidebar .main-menu>li>a>i.fa.fa-folder-open-o {
    font-size: 24px;
    top: 6px !important;
}

tweetbox.ui-draggable-dragging .tweetbox-img-container img.tweet-image {
    display: none;
}

.assets-content.loading-asset-img md-progress-circular {
    margin: 0 auto;
    position: relative;
    z-index: 13;
}

.assets-content.loading-asset-img img.uploading-preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.assets-content.loading-asset-img .assets-name {
    width: 100%;
    padding-right: 10px;
}

.assets-content.loading-asset-img .cancel-upload {
    position: absolute;
    font-size: 25px;
    z-index: 13;
    margin: 0 auto;
    display: inline-block;
    top: 63px;
    margin-left: -17px;
    color: black;
    background-color: rgba(255, 255, 255, 0.64);
    width: 34px;
    height: 34px;
    border-radius: 100%;
    line-height: 35px;
}

.assets-content.loading-asset-img .cancel-upload:hover {
    background-color: rgba(255, 255, 255, 0.32);
}

.assets-search-container .zmdi.zmdi-search {
    margin-top: 8px;
    font-size: 17px;
    font-weight: 100;
    margin-right: 6px;
}

div#from-right aside.toggled {
    right: 0;
    opacity: 1;
    filter: alpha(opacity=100);
}

div#from-left aside {
    left: -500px;
}

div#from-left aside.toggled {
    opacity: 1;
    filter: alpha(opacity=100);
    transform: translateX(560px);
}

.dropdown.twitter-filters a {
    font-size: 20px;
    color: #adadad;
}

.dropdown.twitter-filters>ul {
    line-height: 18px;
}

.dropdown.twitter-filters .checkbox input:checked+.input-helper:before {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}

.dropdown.twitter-filters .checkbox input:checked+.input-helper:after {
    -webkit-transform: scale(1) rotate(-50deg);
    -ms-transform: scale(1) rotate(-50deg);
    -o-transform: scale(1) rotate(-50deg);
    transform: scale(1) rotate(-50deg);
    opacity: 1;
    filter: alpha(opacity=100);
}

.dropdown.twitter-filters .checkbox .input-helper:after {
    content: "";
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(0) rotate(80deg);
    -ms-transform: scale(0) rotate(80deg);
    -o-transform: scale(0) rotate(80deg);
    transform: scale(0) rotate(80deg);
    border-bottom: 2px solid #55acee;
    border-left: 2px solid #55acee;
    border-bottom-left-radius: 2px;
    left: -1px;
    top: 1px;
    width: 22px;
    height: 9px;
    -webkit-transition: all 250ms;
    -o-transition: all 250ms;
    transition: all 250ms;
}

.dropdown.twitter-filters .checkbox .input-helper:before {
    -webkit-transition: all 250ms;
    -o-transition: all 250ms;
    transition: all 250ms;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    content: "";
    border: 1px solid #ccc;
    left: 0;
    top: 0;
    width: 17px;
    height: 17px;
}

.dropdown.twitter-filters .checkbox input:checked+.input-helper:before {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}

.dropdown.twitter-filters label {
    margin-left: 7px;
}

.dropdown.twitter-filters input[type=checkbox] {
    display: none;
}

a.assets-dropdown-sidebar.selected {
    background-color: #f8f8f8;
}

.twitter-sidebar-actions-container>.actions>a:after {
    left: -12px;
    top: -2px;
}

pixabaybox:nth-of-type(odd),
.assets-results>*:nth-child(odd) {
    margin-right: 10px;
}

pixabaybox,
assetsbox {
    width: 227px;
}

.assets-results>.repeat-animation.ng-leave {
    -webkit-transition: none;
    transition: none;
}

.lang-menu-option {
    color: #000;
    font-weight: normal;
}

.lang-menu-option-active {
    font-weight: 600;
    background: none !important;
}

.twitter-sidebar-header-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.language-menu-container {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 29px;
}

.twitter-lang-menu-content {
    position: absolute !important;
    top: -44px;
    width: 130px;
}
