/*.image-cropper > div > img {*/
/*    width: 309px;*/
/*    height: 189px;*/
/*    background-color: #000;*/
/*    object-fit: contain;*/
/*}*/
.image-cropper{
    max-width:309px;
    max-height: 189px;
}

.image-cropper > div > .cropper,
.image-cropper-strip > div > .cropper {
    --cropper-outline-color: rgba(0, 0, 0, .56);
}

.image-cropper > div > .cropper:after,
.image-cropper-strip > div > .cropper:after {
    border: 4px solid #fffcfc !important;
}

.image-crop-container {
    width: 100%;
    padding: 4px;
}

.image-crop-container > .image-cropper {
    padding: 0;
    border-radius: 7px;
}

@media only screen and (max-width: 767px) {
    .image-crop-container {
        padding: 0;
    }

    .image-cropper > div > img {
        width: 100%;
    }
}

.image-cropper-strip img.source-image {
    max-height: 580px !important;
}
